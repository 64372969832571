import { configureStore, combineReducers } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import AuthReducer, { resetAuth } from './reducers/AuthSlice'
import VehicalSlice, { vehicalActions } from './reducers/VehicalSlice'
import DailyRootSlice, { dailyRootActions } from './reducers/DailyRootSlice'
import GrnSlice, {resetGrn} from './reducers/GrnSlice'
import ItemSlice, { resetItem } from './reducers/ItemSlice'
import AlertSlice, { resetToInitial } from './reducers/AlertSlice'
import UserSlice, { resetUsers } from './reducers/UserSlice'
import Product, { resetProduct } from './reducers/ProductSlice'
import SalesSlice, { salesActions } from './reducers/SalesSlice'
import EmployeeSlice, { employeeActions } from './reducers/EmployeeSlice'
import BankSlice, { bankActions } from './reducers/BankSlice';
import OutletSlice, { outletActions } from './reducers/OutletSlice'
import CreditSlice, { creditActions } from './reducers/CreditSlice'
import ChequeSlice, { chequeActions } from './reducers/ChequeSlice'
import CashSlice, { CashActions } from './reducers/CashSlice'
import DiscountSlice, { discountActions } from './reducers/DiscountSlice'
import PettyCashCategorySlice, { PettyCashCategoryActions } from './reducers/PettyCashSlice'
import CreditorChequeDueSlice, { creditorChequeDueActions } from './reducers/CreditorChequeDueSlice'
import DayEndSlice, { dayendActions } from './reducers/DayEndSlice'
import BottleItemSlice, { BottleItemActions } from './reducers/BottleItemSlice'
import BrnSlice, { BrnActions } from './reducers/BrnSlice'
import AccountSlice, { accountActions } from './reducers/AccountSlice'
import ExpenseSlice, { expenseAction } from './reducers/ExpenseSlice'
import AppConfig from './reducers/AppConfigs';
// import thunk from 'redux-thunk';
// import RootReducer from './reducers/RootReducer';

// const initialState = {};
// const middlewares = [thunk];

import { 
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER, 
} from 'redux-persist'

const persistConfig = {
  key: 'root',
  storage: storage,
  // blacklist: ['subFeature', 'subRole',''],
  whitelist:['auth', 'grn', 'product', 'config']
}

const rootReducer = combineReducers({
  auth: AuthReducer,
  vehical: VehicalSlice,
  dailyRoot: DailyRootSlice,
  grn: GrnSlice,
  items: ItemSlice,
  alert: AlertSlice,
  user: UserSlice,
  product: Product,
  sales: SalesSlice,
  employee: EmployeeSlice,
  bank: BankSlice,
  outlet: OutletSlice,
  credit: CreditSlice,
  cheque: ChequeSlice,
  cash: CashSlice,
  discount: DiscountSlice,
  pettycashcategory: PettyCashCategorySlice,
  creditorChequeDue: CreditorChequeDueSlice,
  dayend: DayEndSlice,
  bottleItem: BottleItemSlice,
  brn: BrnSlice,
  account: AccountSlice,
  expense: ExpenseSlice,
  config: AppConfig
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const Store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
    // .concat(api)
})

export const resetAllReducers = (logout = false) => (dispatch) => {
  if(logout) dispatch(resetAuth()) 
  dispatch(resetGrn())
  dispatch(resetItem())
  dispatch(resetToInitial())
  dispatch(resetUsers())
  dispatch(resetProduct())
  dispatch(salesActions.resetState())
  dispatch(dailyRootActions.resetState())
  dispatch(vehicalActions.resetState())
  dispatch(employeeActions.resetState())
  dispatch(bankActions.resetState())
  dispatch(outletActions.resetState())
  dispatch(creditActions.resetState())
  dispatch(chequeActions.resetState())
  dispatch(CashActions.resetState())
  dispatch(discountActions.resetState())
  dispatch(PettyCashCategoryActions.resetState())
  dispatch(creditorChequeDueActions.resetState())
  dispatch(dayendActions.resetState())
  dispatch(BottleItemActions.reset())
  dispatch(BrnActions.resetBrn())
  dispatch(accountActions.resetState())
  dispatch(expenseAction.resetState())
}

export const persistor = persistStore(Store)

