import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL, HTTP_STATUS } from '../../utils/constant'
import { newAxiosInstance } from '../../../axios'
import UrlBuilder from 'app/utils/UrlBuilder'

const initialState = {    
    outlets : [],
    status: HTTP_STATUS.IDLE,
    deleteStatus: HTTP_STATUS.IDLE
}

export const fetchOutlets = createAsyncThunk('outlet/get-all', async (data = null, { rejectWithValue }) => {
    data = data === null ? { Page: 1, PageSize: 10 } : data;
    let url = new UrlBuilder().searchQuery(data).filter(data, 'Agency_id').filter(data, 'root_id').setPage(data).setPageSize(data).getUrl();
    try {
        const response = await newAxiosInstance.get(BASE_URL + `/api/Outlet${url}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const createOutlets = createAsyncThunk('outlet/create', async (postdata, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.post(BASE_URL + '/api/Outlet', postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const updateOutlets = createAsyncThunk('outlet/update', async (postdata, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.put(BASE_URL + `/api/Outlet/${postdata.id}`, postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const deleteOutlets = createAsyncThunk('outlet/delete', async (id, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.delete(BASE_URL + `/api/Outlet/${id}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const Outlets = createSlice({
    name: 'outlet',
    initialState,
    reducers: {
        resetState: (state, action) => {
            return initialState;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchOutlets.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchOutlets.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.outlets = action.payload
            })
            .addCase(fetchOutlets.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })            
            .addCase(deleteOutlets.pending, (state, action) => {
                state.deleteStatus = HTTP_STATUS.LOADING
            })
            .addCase(deleteOutlets.fulfilled, (state, action) => {
                state.deleteStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(deleteOutlets.rejected, (state, action) => {
                state.deleteStatus = HTTP_STATUS.FAILED
            })
    }
})

export const getOutlets = (state) => state.outlet.outlets;
export const getOutletsStatus = (state) => state.outlet.status;
export const getOutletsDeleteStatus = (state) => state.outlet.deleteStatus;

export const outletActions = Outlets.actions

export default Outlets.reducer