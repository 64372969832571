import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL, HTTP_STATUS } from '../../utils/constant'
import { newAxiosInstance } from '../../../axios'
import UrlBuilder from 'app/utils/UrlBuilder'

const initialState = {    
    vehicals : [],
    status: HTTP_STATUS.IDLE,
    deleteStatus: HTTP_STATUS.IDLE
}

export const fetchVehical = createAsyncThunk('vehical/get-all', async (data = null, { rejectWithValue }) => {
    try {
        data = data === null ? { Page: 1, PageSize: 10 } : data;
        let url = new UrlBuilder().searchQuery(data).filter(data, 'Agency_id').setPage(data).setPageSize(data).getUrl();
        const response = await newAxiosInstance.get(BASE_URL + `/api/Vehical${url}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const createVehical = createAsyncThunk('vehical/create-vehical', async (postdata = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.post(BASE_URL + '/api/Vehical', postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const updateVehical = createAsyncThunk('vehical/update-vehical', async (postdata = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.put(BASE_URL + `/api/Vehical/${postdata.id}`, postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const deleteVehical = createAsyncThunk('vehical/delete-vehical', async (id, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.delete(BASE_URL + `/api/Vehical/${id}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const Vehical = createSlice({
    name: 'vehical',
    initialState,
    reducers: {
        resetState: (state, action) => {
            return initialState;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchVehical.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchVehical.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.vehicals = action.payload
            })
            .addCase(fetchVehical.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(deleteVehical.pending, (state, action) => {
                state.deleteStatus = HTTP_STATUS.LOADING
            })
            .addCase(deleteVehical.fulfilled, (state, action) => {
                state.deleteStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(deleteVehical.rejected, (state, action) => {
                state.deleteStatus = HTTP_STATUS.FAILED
            })
    }
})

export const getVehical = (state) => state.vehical.vehicals;
export const getVehicalStatus = (state) => state.vehical.status;
export const getVehicalDeleteStatus = (state) => state.vehical.deleteStatus;

export const vehicalActions = Vehical.actions

export default Vehical.reducer