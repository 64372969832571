import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Accounts = Loadable(lazy(() => import('./Accounts')));
const PettyCash = Loadable(lazy(() => import('./PettyCash/PettyCash')));
const ChequeStatus = Loadable(lazy(() => import('./ChequeStatus/ChequeStatus')));
const CreditStatus = Loadable(lazy(() => import('./CreditStatus/CreditStatus')));
const CreateCheque = Loadable(lazy(() => import('./ChequeStatus/Create/Create')));
const CreateExpese = Loadable(lazy(() => import('./Expense/CreateExpense/CreateExpense')));
const Expense = Loadable(lazy(() => import('./Expense/Expense')));
const CreateCredit = Loadable(lazy(() => import('./CreditStatus/Create/Create')));
const PendingBottleLoan = Loadable(lazy(() => import('./BottleLoan/PendingLoan/PendingLoan')));
const CreateBottleLoan = Loadable(lazy(() => import('./BottleLoan/Create/Create')));
const BottleLoanCollected = Loadable(lazy(() => import('./BottleLoan/BottleLoanCollected/BottleLoanCollected')));

const stockRoutes = [
  { path: '/accounts/petty-cash', element: <PettyCash />, auth: authRoles.guest },
  { path: '/accounts/pending-cheque-management/create-cheque', element: <CreateCheque />, auth: authRoles.sa },
  { path: '/accounts/pending-cheque-management', element: <ChequeStatus />, auth: authRoles.sa },
  { path: '/accounts/pending-credit-management/create-credit-bill', element: <CreateCredit />, auth: authRoles.sa },
  { path: '/accounts/direct-bank-payment', element: <CreateCredit bankRoute />, auth: authRoles.sa },
  { path: '/accounts/pending-credit-management/update-credit-bill/:id', element: <CreateCredit />, auth: authRoles.sa },
  { path: '/accounts/pending-credit-management', element: <CreditStatus />, auth: authRoles.sa },
  { path: '/accounts/pending-bottle-loan-management', element: <PendingBottleLoan />, auth: authRoles.guest, routeRestrict: true },
  { path: '/accounts/pending-bottle-loan-management/create-loan', element: <CreateBottleLoan />, auth: authRoles.sa, routeRestrict: true },
  { path: '/accounts/pending-bottle-loan-management/update-loan/:id', element: <CreateBottleLoan />, auth: authRoles.sa, routeRestrict: true },
  { path: '/accounts/bottle-loan-collected', element: <BottleLoanCollected />, auth: authRoles.guest, routeRestrict: true  },
  { path: '/accounts/expenses/create-expense', element: <CreateExpese />, auth: authRoles.guest },
  { path: '/accounts/expenses/pay-pending-balance/:id', element: <CreateExpese />, auth: authRoles.guest },
  { path: '/accounts/expenses/:payableId', element: <Expense />, auth: authRoles.guest },
  { path: '/accounts/expenses', element: <Expense />, auth: authRoles.guest },
  { path: '/accounts/:route', element: <Accounts />, auth: authRoles.guest  }
];

export default stockRoutes;