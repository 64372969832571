import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { syncAuthToken, getAuth } from 'app/redux/reducers/AuthSlice'; // Your action to update tokens in Redux

const useSyncTokensAcrossTabs = () => {

  const dispatch = useDispatch();
  const auth = useSelector(getAuth);

  // Flag to prevent handling storage event triggered by current tab itself

  useEffect(() => {
    const syncTokens = (event) => {
      if (event.key === 'persist:root') {  // or another key you're using for redux-persist
        // Local storage has changed, update the Redux state
        const newState = JSON.parse(event.newValue);
        const newAuth = JSON.parse(newState?.auth || '{}');
        const newAccessToken = newAuth?.auth?.token || null; // Change based on your state shape
        const newRefreshToken = newAuth?.auth?.refreshToken || null;
        
        if (newAccessToken !== auth.token || newRefreshToken !== auth.refreshToken) {
            // Dispatch action to update Redux state
            dispatch(syncAuthToken({ token: newAccessToken, refreshToken: newRefreshToken }));
        }
        
      }
    };

    // Add listener for local storage changes
    window.addEventListener('storage', syncTokens);

    // Cleanup the listener when the component unmounts
    return () => {
      window.removeEventListener('storage', syncTokens);
    };
  }, [auth?.token, auth?.refreshToken, dispatch]);

};

export default useSyncTokensAcrossTabs;