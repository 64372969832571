import { Button, Dialog, styled } from '@mui/material';
import { useDispatch } from "react-redux";
import LoadingButton from '@mui/lab/LoadingButton';
import useDialog from 'app/hooks/useDialog';
import { setAlert } from 'app/redux/reducers/AlertSlice'
import { HTTP_EX_MZG } from 'app/utils/constant'

const DialogBox = styled('div')(() => ({
  width: 360,
  padding: '32px',
  textAlign: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
}));

const Title = styled('h4')(() => ({
  margin: 0,
  marginBottom: '8px',
  textTransform: 'capitalize',
}));

const Controller = styled('div')(() => ({
  margin: '8px',
  paddingTop: '8px',
  display: 'flex',
  justifyContent: 'center',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: '8px',
  paddingLeft: '24px',
  paddingRight: '24px',
  overflow: 'hidden',
  borderRadius: '300px',
  transition: 'all 250ms',
  '&.noBtn': {
    '&:hover': {
      color: '#ffffff',
      background: `${theme.palette.secondary.main} !important`,
      backgroundColor: `${theme.palette.secondary.main} !important`,
      fallbacks: [{ color: 'white !important' }],
    },
  },
}));

const LoadingStyledButton = styled(LoadingButton)(({ theme }) => ({
  margin: '8px',
  paddingLeft: '24px',
  paddingRight: '24px',
  overflow: 'hidden',
  borderRadius: '300px',
  transition: 'all 250ms',
  '&.error-yesBtn': {
    '&:hover': {
      color: '#ffffff',
      background: `#FF3D57 !important`,
      backgroundColor: `#FF3D57 !important`,
      border: `1px solid #FF3D57`,
      fallbacks: [{ color: 'white !important' }],
    },
  },
  '&.primary-yesBtn': {
    '&:hover': {
      color: '#ffffff',
      background: `${theme.palette.primary.main} !important`,
      backgroundColor: `${theme.palette.primary.main} !important`,
      fallbacks: [{ color: 'white !important' }],
    },
  }
}));

const ConfirmationDialog = () => {

  const { confirm, handleOpen } = useDialog();
  const dispatch = useDispatch();

  let handleClose = () => {
    handleOpen({...confirm, open: false, confirmFunc: () => {}})
  }
  
  return (
    <Dialog maxWidth="xs" open={confirm.open}>
      <DialogBox>
        <Title>{confirm.title}</Title>
        <p dangerouslySetInnerHTML={{__html: confirm.text}} />
        <Controller>
          <LoadingStyledButton loading={confirm.loading} className={confirm.buttonColor === 'error' ? 'error-yesBtn' : 'primary-yesBtn'} color={confirm.buttonColor} variant="outlined" onClick={async () => {
            if(confirm.confirmFunc.constructor.name === 'AsyncFunction'){
              await confirm.confirmFunc().catch(ex => { 
                dispatch(setAlert({
                  open: true,
                  text: HTTP_EX_MZG,
                  severity: 'error'
                }));
              }) 
              handleClose();               
            }else{
              confirm.confirmFunc();
              handleClose(); 
            }       
          }}>
            {confirm.buttonText}
          </LoadingStyledButton>
          {
            confirm.cancleButtonColor !== "" && confirm.cancleButtonText !== "" ? <StyledButton
                              className="noBtn"
                              variant="outlined"
                              color={confirm.cancleButtonColor}
                              onClick={() => {
                                confirm.cancelFunc();
                                handleClose();
                              }}
                            >
                              {confirm.cancleButtonText}
                            </StyledButton> : null
          }
          
        </Controller>
      </DialogBox>
    </Dialog>
  );
};

export default ConfirmationDialog;
