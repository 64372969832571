import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL, HTTP_STATUS } from '../../utils/constant'
import { newAxiosInstance } from '../../../axios'

const initialState = {    
    categories : [],
    items: [],
    pettycashList: [],
    curDate: null,
    status: HTTP_STATUS.IDLE,
    broughtForwardStatus: HTTP_STATUS.IDLE,
    deleteStatus: HTTP_STATUS.IDLE,
    pettycashStatus: HTTP_STATUS.IDLE,
}

export const fetchCategories = createAsyncThunk('PettyCashCategory/get-all', async (postdata = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.get(BASE_URL + '/api/PettyCashCategory')
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const createCategories = createAsyncThunk('PettyCashCategory/create', async (postdata, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.post(BASE_URL + '/api/PettyCashCategory', postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const updateCategories = createAsyncThunk('PettyCashCategory/update', async (postdata, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.put(BASE_URL + `/api/PettyCashCategory/${postdata.id}`, postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const deleteCategories = createAsyncThunk('PettyCashCategory/delete', async (id, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.delete(BASE_URL + `/api/PettyCashCategory/${id}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const fetchItems = createAsyncThunk('PettycashItem/get-all', async (postdata = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.get(BASE_URL + '/api/PettycashItem')
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchItemsByCategory = createAsyncThunk('PettycashItem/get-by-category', async (id, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.get(BASE_URL + `/api/PettycashItem/get-item-by-category/${id}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const createItems = createAsyncThunk('PettycashItem/create', async (postdata, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.post(BASE_URL + '/api/PettycashItem', postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const updateItems = createAsyncThunk('PettycashItem/update', async (postdata, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.put(BASE_URL + `/api/PettycashItem/${postdata.id}`, postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const deleteItems = createAsyncThunk('PettycashItem/delete', async (id, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.delete(BASE_URL + `/api/PettycashItem/${id}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const createPettycashRecord = createAsyncThunk('PettycashRecord/create', async (postdata, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.post(BASE_URL + '/api/Pettycash', postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const fetchPettyCash = createAsyncThunk('PettycashRecord/get-by-date', async (postdata = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.get(BASE_URL + `/api/Pettycash?p_cash_date=${postdata.p_cash_date}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchCurrentDate = createAsyncThunk('PettycashRecord/get-current-date-time', async (postdata = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.get(BASE_URL + '/api/Pettycash/get-current-date-time')
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchBalance = createAsyncThunk('PettycashRecord/get-balance', async ({balance_type, date_time}, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.get(BASE_URL + `/api/Pettycash/get-balance?balance_type=${balance_type}&p_cash_date=${date_time}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchBroughtForward = createAsyncThunk('PettycashRecord/brought-forward', async (data = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.get(BASE_URL + '/api/Pettycash/brought-forward')
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const Categories = createSlice({
    name: 'pettycashcategory',
    initialState,
    reducers: {
        resetState: (state, action) => {
            return initialState;
        },
        resetCategories: (state, action) => {
            state.categories = [];
        },
        resetPettyCash: (state, action) => {
            state.pettycash = [];
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchCategories.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchCategories.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.categories = action.payload
            })
            .addCase(fetchCategories.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(fetchItems.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchItems.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.items = action.payload
            })
            .addCase(fetchItems.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(fetchItemsByCategory.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchItemsByCategory.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.items = action.payload
            })
            .addCase(fetchItemsByCategory.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(deleteCategories.pending, (state, action) => {
                state.deleteStatus = HTTP_STATUS.LOADING
            })
            .addCase(deleteCategories.fulfilled, (state, action) => {
                state.deleteStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(deleteCategories.rejected, (state, action) => {
                state.deleteStatus = HTTP_STATUS.FAILED
            })
            .addCase(deleteItems.pending, (state, action) => {
                state.deleteStatus = HTTP_STATUS.LOADING
            })
            .addCase(deleteItems.fulfilled, (state, action) => {
                state.deleteStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(deleteItems.rejected, (state, action) => {
                state.deleteStatus = HTTP_STATUS.FAILED
            })
            .addCase(createPettycashRecord.pending, (state, action) => {
                state.pettycashStatus = HTTP_STATUS.LOADING
            })
            .addCase(createPettycashRecord.fulfilled, (state, action) => {
                state.pettycashStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(createPettycashRecord.rejected, (state, action) => {
                state.pettycashStatus = HTTP_STATUS.FAILED
            })
            .addCase(fetchPettyCash.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchPettyCash.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.pettycashList = action.payload
            })
            .addCase(fetchPettyCash.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(fetchBroughtForward.pending, (state, action) => {
                state.broughtForwardStatus = HTTP_STATUS.LOADING
            })
            .addCase(fetchBroughtForward.fulfilled, (state, action) => {
                state.broughtForwardStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(fetchBroughtForward.rejected, (state, action) => {
                state.broughtForwardStatus = HTTP_STATUS.FAILED
            })
            .addCase(fetchCurrentDate.fulfilled, (state, action) => {
                state.curDate = action.payload
            })
    }
})

export const getCategories = (state) => state.pettycashcategory.categories;
export const getItems = (state) => state.pettycashcategory.items;
export const getCurdate = (state) => state.pettycashcategory.curDate;
export const getPettyCash = (state) => state.pettycashcategory.pettycashList;
export const getCategoriesStatus = (state) => state.pettycashcategory.status;
export const getCategoriesDeleteStatus = (state) => state.pettycashcategory.deleteStatus;
export const getPettyCashStatus = (state) => state.pettycashcategory.pettycashStatus;
export const getBroughtForwardStatus = (state) => state.pettycashcategory.broughtForwardStatus;

export const PettyCashCategoryActions = Categories.actions

export default Categories.reducer