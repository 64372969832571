import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Grn = Loadable(lazy(() => import('./GRN/GRN')));
const Brn = Loadable(lazy(() => import('./BRN/BRN')));
const AddStock = lazy(() => import('./AddStock/AddStock'));
const AddItem = lazy(() => import('./AddItem/AddItem'));
const Stock = lazy(() => import('./Stock/Stock'));
const BottleStock = lazy(() => import('./BottleStock/Stock'));
const AddBottleStockItem = lazy(() => import('./BottleStock/AddItem/AddItem'));
const AddBottleStock = lazy(() => import('./BottleStock/AddStock/AddStock'));

const inventoryRoutes = [
  { path: '/inventory/grn', element: <Grn />, auth: authRoles.guest  },
  { path: '/inventory/brn', element: <Brn />, auth: authRoles.guest, routeRestrict: true  },
  { path: '/inventory/brn-purchase-order', element: <Brn pOrder />, auth: authRoles.guest, routeRestrict: true  },
  { path: '/inventory/stock', element: <Stock />, auth: authRoles.guest } , 
  { path: '/inventory/stock/create-stock', element: <AddStock />, auth: authRoles.sa}, 
  { path: '/inventory/stock/update-stock/:id', element: <AddStock />, auth: authRoles.sa}, 
  { path: '/inventory/stock/create-item', element: <AddItem />, auth: authRoles.sa}, 
  { path: '/inventory/stock/create-item/:id', element: <AddItem />, auth: authRoles.sa},
  { path: '/inventory/bottle/stock', element: <BottleStock />, auth: authRoles.guest },
  { path: '/inventory/bottle/stock/create-item', element: <AddBottleStockItem />, auth: authRoles.sa}, 
  { path: '/inventory/bottle/stock/create-item/:id', element: <AddBottleStockItem />, auth: authRoles.sa}, 
  { path: '/inventory/bottle/stock/create-stock', element: <AddBottleStock />, auth: authRoles.sa}, 
  { path: '/inventory/bottle/stock/update-stock/:id', element: <AddBottleStock />, auth: authRoles.sa}, 
  { path: '/inventory/bottle/stock/update-stock/unit-price-change/:id', element: <AddBottleStock uprice_update />, auth: authRoles.sa}, 
];

export default inventoryRoutes;