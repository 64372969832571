import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Sales = Loadable(lazy(() => import('./Sales')));
const CreateLoading = Loadable(lazy(() => import('./Loading/create/Create')));
const EmptyBottles = Loadable(lazy(() => import('./EmptyBottles/EmptyBottles')));

const stockRoutes = [
  { path: '/sales/loading/create', element: <CreateLoading />, auth: authRoles.guest  },
  { path: '/sales/loading/update/:id', element: <CreateLoading />, auth: authRoles.guest  },
  { path: '/sales/empty-bottles', element: <EmptyBottles />, auth: authRoles.guest, routeRestrict: true },
  { path: '/sales/:route', element: <Sales />, auth: authRoles.guest  }
];

export default stockRoutes;