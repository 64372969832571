export const topBarHeight = 64
export const sideNavWidth = 260
export const drawerWidth = 600
export const navbarHeight = 60
export const sidenavCompactWidth = 80
export const containedLayoutWidth = 1200
export const MAXIMUM_DATE_PERIOD = 90
export const BASE_URL = process.env['NODE_ENV'] === 'development' ? 'http://localhost:5256' : '';
export const REPORT_BASE_URL = process.env['NODE_ENV'] === 'development' ? 
                                'https://reportviewer.shenukdistributors.com/web/viewer.html' : 
                                'https://reportviewer.shenukdistributors.com/web/viewer.html';
export const CONFLICT_STATUS_CODE = 409;
export const VALIDATION_STATUS_CODE = 422;
export const NOT_FOUND = "/not-found";
export const HTTP_STATUS = {
    IDLE: "idle",
    LOADING: "loading",
    SUCCEEDED: "succeeded",
    FAILED: "failed"
}
export const NAME_VALIDATION = /^\S[a-zA-Z0-9-&%\s]+\S$/;
export const NAME_VALIDATION_MZG = "should contain numbers, letters, dashes and spaceses only";
export const NAME_VALIDATION_CAPITAL_LETTERS = /^\S[A-Z0-9-]+\S$/;
export const NAME_VALIDATION_MZG_CAPITAL_LETTERS = "should contain numbers, capital letters, dashes only";
export const NAME_VALIDATION_NOT_SPACES = /^[a-zA-Z0-9-&%]+$/;
export const NAME_VALIDATION_MZG_NOT_SPACES = "should contain numbers, letters and dashes only";
export const HTTP_EX_MZG = "Something Went Wrong Please Contact System Administrator";
export const TABLE_PAGE_SIZE = [10, 20, 50, 100];
export const Bottle_doz = 12;
export const CAPACITY_MEASURMENT = {
    0: "ML ( Milliliter )",
    1: "G ( Gram )"
}

export const CAPACITY_MEASURMENT_CONVERT = {
    0: "L",
    1: "G"
}

export const VEHICAL_TYPES = {
    0: "INHOUSE VEHICAL",
    1: "TEMPORARY VEHICAL"
}

export const EMPLOYEE_TYPE = {
    0: "INHOUSE EMPLOYEE",
    1: "TEMPORARY EMPLOYEE"
}

export const STOCK_ADJESTMENT = {
    0: "ADD",
    1: "REMOVE"
}

export const WAREHOUSE = {
    0: "RD",
    1: "PRI",
    2: "OUTLET",
    3: "DAMAGE"
}

export const WAREHOUSE_OPPOSIT = {
    "RD": 0,
    "PRI": 1,
    "OUTLET": 2,
    "DAMAGE": 3
}

export const RETURN_TYPE = {
    0: "EXPIRED",
    1: "GOODS"
}

export const TR_TYPE = {
    0: "CREDIT",
    1: "DEBIT"
}

export const TR_TYPE_OPPOSIT = {
    "CREDIT": 0,
    "DEBIT": 1
}

export const CHEQUE_STATUS = {
    PENDING : 0,
    BANKED: 1,
    RETURNED: 2,
    CHANGED: 3
}

export const CHEQUE_STATUS_OPPOSE = {
    0: "PENDING",
    1: "BANKED",
    2: "RETURNED",
    3: "CHANGED"
}

export const CHEQUE_STATUS_COLOR = {
    PENDING: "primary",
    BANKED: "secondary",
    RETURNED: "error",
    CHANGED: "success"
}

export const STOCK_EXISTANCE =
{
    STOCK_EXIST : 0,
    STOCK_NOTEXIST: 1
}

export const CREDIT_COLLECTED_BY = {
    0: "CASH",
    1: "CHEQUE",
    3: "BANK"
}

export const CREDIT_COLLECTED_BY_OPPOSE = {
    "CASH": 0,
    "CHEQUE": 1,
    "BANK": 3
}

export const CREDIT_CREATED_AS = {
    2: "CREDIT",
    3: "BANK"
}

export const CREDIT__CREATED_AS_OPPOSE = {
    "CREDIT": 2,
    "BANK": 3
}

export const BOTTLE_RETURN_TYPE = {
    0: "BOTTLE",
    1: "LOAN",
    2: "PURCHASE",
    3: "DAMAGE"
}

export const BOTTLE_RETURN_TYPE_OPPOSE = {
    BOTTLE: 0,
    LOAN: 1,
    PURCHASE: 2,
    DAMAGE: 3
}

export const CHEQUE_STATUS_FILTER = [
    {id: 1, label: "Banked"}, 
    {id: 2, label: "Returned"}, 
    {id: 3, label: "Changed"}
]

export const DISCOUNT_GIVEN_BY = {
    0: "N/A",
    1: "CASH",
}

export const DISCOUNT_GIVEN_BY_OPPOS = {
    "N/A" : 0,
    "CASH" : 1
}

export const ACCOUNT_TYPES = {
    Asset: 0,
    Liability: 1,
    Revenue: 2,
    Expense: 3,
    Equity: 4,
    ContraRevenue: 5
}

export const CHEQUE_TYPES = [
    { id: 1, label: "Receivables" },
    { id: 2, label: "Payable"}
]

export const EXPENSE_PAYMENT_SOURCE = {
    0: "CASH",
    1: "CHEQUE",
    2: "FULL-PAID",
    3: "BANK",
    4: "PAYABLE"
}

export const EXPENSE_PAYMENT_SOURCE_OPPOSE = {
    "CASH" : 0,
    "CHEQUE" : 1,
    "FULL-PAID": 2,
    "BANK" : 3,
    "PAYABLE" : 4
}

export const EXPENSE_TRACK = [
    { id: 1, label: "Payable", disabled: false },
    { id: 2, label: "History", disabled: false},
    { id: 3, label: "Payable History", disabled: true }
]

export const EEPENSE_STATUS_COLOR = {
    0: "success",
    1: "secondary",
    2: "success",
    3: "success",
    4: "warning",
    5: "error"
}

export const TRANSACTION_TYPE_COLOR = {
    DEBIT: "error",
    CREDIT: "success"
}

export const CREDIT_MARGIN_BY = [
    { id: 1, label: "%" },
    { id: 2, label: "+"}
]

export const CREDIT_MARGIN_INDEX = {
    1: 0,
    2: 1
}