class UrlBuilder {

    constructor(){
        this.urlparam = "?";
    }

    searchQuery(data){
        this.urlparam += data?.searchQuery ? `searchQuery=${data?.searchQuery}&` : '';
        return this;
    }

    filter(data, filter){
        this.urlparam +=  data[filter] ? `${filter}=${data[filter]}&` : '';
        return this;
    }

    setPage(data){
        this.urlparam += data?.Page ? `Page=${data.Page}&`: '';
        return this; 
    }

    setPageSize(data){
        this.urlparam += data?.PageSize ? `PageSize=${data?.PageSize}&`: '';
        return this
    }

    getUrl(){
        return this.urlparam.substring(0, this.urlparam.length - 1);
    }
}

export default UrlBuilder;