import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL, HTTP_STATUS } from '../../utils/constant'
import { newAxiosInstance } from '../../../axios'

const initialState = {    
    root : [],
    status: HTTP_STATUS.IDLE,
    deleteStatus: HTTP_STATUS.IDLE
}

export const fetchDailyRoot = createAsyncThunk('dailyRoot/get-all', async (postdata = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.get(BASE_URL + '/api/DailyRoute')
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const createDailyRoot = createAsyncThunk('dailyRoot/create', async (postdata, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.post(BASE_URL + '/api/DailyRoute', postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const updateDailyRoot = createAsyncThunk('dailyRoot/update', async (postdata, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.put(BASE_URL + `/api/DailyRoute/${postdata.id}`, postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const deleteDailyRoot = createAsyncThunk('dailyRoot/delete', async (id, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.delete(BASE_URL + `/api/DailyRoute/${id}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const DailyRoot = createSlice({
    name: 'dailyRoot',
    initialState,
    reducers: {
        resetState: (state, action) => {
            return initialState;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchDailyRoot.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchDailyRoot.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.root = action.payload
            })
            .addCase(fetchDailyRoot.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(deleteDailyRoot.pending, (state, action) => {
                state.deleteStatus = HTTP_STATUS.LOADING
            })
            .addCase(deleteDailyRoot.fulfilled, (state, action) => {
                state.deleteStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(deleteDailyRoot.rejected, (state, action) => {
                state.deleteStatus = HTTP_STATUS.FAILED
            })
    }
})

export const getDailyRoot = (state) => state.dailyRoot.root;
export const getDailyRootStatus = (state) => state.dailyRoot.status;
export const getDailyRootDeleteStatus = (state) => state.dailyRoot.deleteStatus;

export const dailyRootActions = DailyRoot.actions

export default DailyRoot.reducer