import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL, HTTP_STATUS } from '../../utils/constant'
import { newAxiosInstance } from '../../../axios'

const initialState = {    
    employees : [],
    base_roles: [],
    employee_roles: [],
    employee_proportions: [],
    status: HTTP_STATUS.IDLE,
    deleteStatus: HTTP_STATUS.IDLE
}

export const fetchEmpRoleByProduct = createAsyncThunk('employee/get-role/by-product', async (productId = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.get(`${BASE_URL}/api/EmployeeRole/get-by-product/${productId}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const createEmployeeRole = createAsyncThunk('employee/role/create', async (postdata, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.post(`${BASE_URL}/api/EmployeeRole`, postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const updateEmployeeRole = createAsyncThunk('employee/role/update', async (postdata, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.put(`${BASE_URL}/api/EmployeeRole/${postdata.id}`, postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const deleteEmployeeRole = createAsyncThunk('employee/role/delete', async (id, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.delete(`${BASE_URL}/api/EmployeeRole/${id}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchEmployeeByRole = createAsyncThunk('employee/get-by-Role', async (roleId = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.get(`${BASE_URL}/api/Employee/get-employee-by-role/${roleId}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchEmployeeByProduct = createAsyncThunk('employee/get-by-product', async (productId = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.get(`${BASE_URL}/api/Employee/get-employee-by-product/${productId}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const createEmployee = createAsyncThunk('employee/create', async (postdata, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.post(`${BASE_URL}/api/Employee`, postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const updateEmployee = createAsyncThunk('employee/update', async (postdata, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.put(`${BASE_URL}/api/Employee/${postdata.id}`, postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const deleteEmployee = createAsyncThunk('employee/delete', async (id, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.delete(`${BASE_URL}/api/Employee/${id}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchSalesRefByProduct = createAsyncThunk('employee/get-sales-reff/by-product', async (productId = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.get(`${BASE_URL}/api/Employee/get-sales-ref-by-product/${productId}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchAlternativeRolesByProduct = createAsyncThunk('employee/alternative-roles/get-by-product', async (productId = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.get(`${BASE_URL}/api/EmployeeProportion/get-proportion-by-product/${productId}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const createAlternativeRoles = createAsyncThunk('employee/alternative-roles/create', async (postdata, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.post(`${BASE_URL}/api/EmployeeProportion`, postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const updateAlternativeRoles = createAsyncThunk('employee/alternative-roles/update', async (postdata, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.put(`${BASE_URL}/api/EmployeeProportion/${postdata.id}`, postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const deleteAlternativeRoles = createAsyncThunk('employee/alternative-roles/delete', async (id, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.delete(`${BASE_URL}/api/EmployeeProportion/${id}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchEmployeeBaseRole = createAsyncThunk('employee/base-role/get-all', async (roleId = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.get(`${BASE_URL}/api/EmployeeBaseRole`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const createEmployeeBaseRole = createAsyncThunk('employee/base-role/create', async (postdata, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.post(`${BASE_URL}/api/EmployeeBaseRole`, postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const updateEmployeeBaseRole = createAsyncThunk('employee/base-role/update', async (postdata, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.put(`${BASE_URL}/api/EmployeeBaseRole/${postdata.id}`, postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const deleteEmployeeBaseRole = createAsyncThunk('employee/base-role/delete', async (id, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.delete(`${BASE_URL}/api/EmployeeBaseRole/${id}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchProportionByRole = createAsyncThunk('employee/get-proportion/by-Role', async (roleId = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.get(`${BASE_URL}/api/EmployeeProportion/get-proportion-by-role/${roleId}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const Employee = createSlice({
    name: 'employee',
    initialState,
    reducers: {
        resetState: (state, action) => {
            return initialState;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchEmpRoleByProduct.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchEmpRoleByProduct.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.employee_roles = action.payload
            })
            .addCase(fetchEmpRoleByProduct.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(fetchEmployeeByRole.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchEmployeeByRole.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
            })
            .addCase(fetchEmployeeByRole.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(fetchProportionByRole.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchProportionByRole.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
            })
            .addCase(fetchProportionByRole.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(fetchAlternativeRolesByProduct.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchAlternativeRolesByProduct.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.employee_proportions = action.payload
            })
            .addCase(fetchAlternativeRolesByProduct.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(fetchEmployeeByProduct.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchEmployeeByProduct.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.employees = action.payload
            })
            .addCase(fetchEmployeeByProduct.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(deleteEmployee.pending, (state, action) => {
                state.deleteStatus = HTTP_STATUS.LOADING
            })
            .addCase(deleteEmployee.fulfilled, (state, action) => {
                state.deleteStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(deleteEmployee.rejected, (state, action) => {
                state.deleteStatus = HTTP_STATUS.FAILED
            })
            .addCase(fetchEmployeeBaseRole.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchEmployeeBaseRole.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.base_roles = action.payload
            })
            .addCase(fetchEmployeeBaseRole.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(deleteEmployeeBaseRole.pending, (state, action) => {
                state.deleteStatus = HTTP_STATUS.LOADING
            })
            .addCase(deleteEmployeeBaseRole.fulfilled, (state, action) => {
                state.deleteStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(deleteEmployeeBaseRole.rejected, (state, action) => {
                state.deleteStatus = HTTP_STATUS.FAILED
            })
            .addCase(deleteEmployeeRole.pending, (state, action) => {
                state.deleteStatus = HTTP_STATUS.LOADING
            })
            .addCase(deleteEmployeeRole.fulfilled, (state, action) => {
                state.deleteStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(deleteEmployeeRole.rejected, (state, action) => {
                state.deleteStatus = HTTP_STATUS.FAILED
            })
            .addCase(deleteAlternativeRoles.pending, (state, action) => {
                state.deleteStatus = HTTP_STATUS.LOADING
            })
            .addCase(deleteAlternativeRoles.fulfilled, (state, action) => {
                state.deleteStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(deleteAlternativeRoles.rejected, (state, action) => {
                state.deleteStatus = HTTP_STATUS.FAILED
            })
    }
})

export const getEmployees = (state) => state.employee.employees;
export const getEmployeeBaseRoles= (state) => state.employee.base_roles;
export const getEmployeeRoles = (state) => state.employee.employee_roles;
export const getEmployeeProportions = (state) => state.employee.employee_proportions;
export const getEmployeeStatus = (state) => state.employee.status;
export const getEmployeedeleteStatus = (state) => state.employee.deleteStatus;

export const employeeActions = Employee.actions

export default Employee.reducer