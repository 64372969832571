import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    item: null,
    bottleAllowed: false
}

export const Product = createSlice({
    name: 'product',
    initialState,
    reducers: {
        resetProduct: (state, action) => {
            return initialState
        },
        setProduct: (state, action) => {
            let allowed = action.payload.bottleAllowed;
            state.item = {
                label: action.payload.product.description,
                id: action.payload.product.id
            }
            state.bottleAllowed = allowed.length > 0 ? allowed[0].bottleStockId !== null ? true : false : false;
            return state;
        }
    }
})

export const getProduct = (state) => state.product.item
export const getBottleAllowed = (state) => state.product.bottleAllowed

export const { resetProduct, setProduct } = Product.actions

export default Product.reducer