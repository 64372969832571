import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const SalesReport = Loadable(lazy(() => import('./salesReport/SalesReport')));
const LoadingUnloadingReport = Loadable(lazy(() => import('./loadingUnloadingReport/LoadingUnloadingReport')));
const ExpireReport = Loadable(lazy(() => import('./ExpireReport/ExpireReport')));
const ItemWiseSalesReport = Loadable(lazy(() => import('./ItemWiseSales/ItemWiseSalesReport')));

const inventoryRoutes = [
  { path: '/sales/reports/sales-report', element: <SalesReport />, auth: authRoles.guest  },
  { path: '/sales/reports/loading-unloading-vehical-report', element: <LoadingUnloadingReport />, auth: authRoles.guest  },
  { path: '/sales/reports/expire-report', element: <ExpireReport />, auth: authRoles.guest  },
  { path: '/sales/reports/item-wise-sales-report', element: <ItemWiseSalesReport />, auth: authRoles.guest  },
];

export default inventoryRoutes;