import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL, HTTP_STATUS } from '../../utils/constant'
import { newAxiosInstance } from '../../../axios'
import UrlBuilder from 'app/utils/UrlBuilder'
import { generateDownloadFileName, downloadFile } from 'app/utils/utils';

const initialState = {    
    item : {
        brn_items: []        
    },
    emptyBottles: [],
    pendingLoans: [],
    report: null,
    status: HTTP_STATUS.IDLE,
    createStatus: HTTP_STATUS.IDLE,
    deleteStatus: HTTP_STATUS.IDLE,
    reportStatus: HTTP_STATUS.IDLE
}

export const fetchBrnInvoice = createAsyncThunk('brn/get-invoice', async ({id, data}, { rejectWithValue }) => {
    try {
        let url = new UrlBuilder().searchQuery(data).filter(data, 'fileType').filter(data, 'stDate').filter(data, 'enDate').getUrl();
        let responseType = data?.fileType && parseInt(data.fileType) > 0 ? 'blob' : 'text';
        const response = await newAxiosInstance.get(BASE_URL + `/api/Brn/generate-brn-invoice/${id}${url}`,{ responseType })
        if(responseType === 'text') return response.data
        let filename = generateDownloadFileName(response.headers['content-disposition']);
        downloadFile(response.data, filename);       
        return null
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
    
})

export const fetchBrnPoInvoice = createAsyncThunk('brn/get-po-invoice', async ({id, data}, { rejectWithValue }) => {
    try {
        let url = new UrlBuilder().searchQuery(data).filter(data, 'fileType').filter(data, 'stDate').filter(data, 'enDate').getUrl();
        let responseType = data?.fileType && parseInt(data.fileType) > 0 ? 'blob' : 'text';
        const response = await newAxiosInstance.get(BASE_URL + `/api/Brn/generate-brn-po-invoice/${id}${url}`,{ responseType })
        if(responseType === 'text') return response.data
        let filename = generateDownloadFileName(response.headers['content-disposition']);
        downloadFile(response.data, filename);       
        return null
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
    
})

export const fetchStockJournal = createAsyncThunk('brn/get-stock-journal', async (data, { rejectWithValue }) => {
    try {
        let url = new UrlBuilder().searchQuery(data).filter(data, 'fileType').filter(data, 'stDate').filter(data, 'enDate').filter(data, 'stockId').getUrl();
        let responseType = data?.fileType && parseInt(data.fileType) > 0 ? 'blob' : 'text';
        const response = await newAxiosInstance.get(BASE_URL + `/api/Brn/stock-journal${url}`,{ responseType })
        if(responseType === 'text') return response.data
        let filename = generateDownloadFileName(response.headers['content-disposition']);
        downloadFile(response.data, filename);       
        return null
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
    
})

export const fetchEmptyBottleBySales = createAsyncThunk('brn/get-empty-bottles', async ({salesId, data}, { rejectWithValue }) => {
    try {
        let url = new UrlBuilder().filter(data, 'trType').getUrl();
        const response = await newAxiosInstance.get(BASE_URL + `/api/Brn/get-empty-bottles-by-sales/${salesId}${url}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchLoans = createAsyncThunk('brn/get-loans', async (data, { rejectWithValue }) => {
    try {
        let url = new UrlBuilder().searchQuery(data).filter(data, 'fromDate').filter(data, 'toDate').filter(data, 'id').filter(data, 'repId').getUrl();
        const response = await newAxiosInstance.get(BASE_URL + `/api/Brn/get-pending-loans${url}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const createBrn = createAsyncThunk('brn/create', async (postdata = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.post(BASE_URL + '/api/Brn', postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const createBrnPo = createAsyncThunk('brn/create-po', async (postdata = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.post(BASE_URL + '/api/Brn/create-purchase-order', postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const createEmpty = createAsyncThunk('brn/create-empty', async (postdata = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.post(BASE_URL + '/api/Brn/add-empty-bottles', postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const createLoan = createAsyncThunk('brn/create-loan', async (postdata = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.post(BASE_URL + '/api/Brn/create-loan', postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const deleteEmpty = createAsyncThunk('brn/delete-empty', async (id = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.delete(BASE_URL + `/api/Brn/empty-bottles/${id}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const Brn = createSlice({
    name: 'brn',
    initialState,
    reducers: {
        setBrnItems: (state, action) => {
            state.item.brn_items.push(action.payload);
        },
        removeItemFromBrn: (state, action) => {
            state.item.brn_items.splice(action.payload, 1);
        },
        resetBrn: (state, action) => {
            return initialState;
        },
        resetBrnWithoutItem: (state, action) => {
            state.report = initialState.report
            state.status = initialState.status
            state.reportStatus = initialState.reportStatus
        },
        resetEmptyBottles: (state, action) => {
            state.emptyBottles = []
        }
    },
    extraReducers(builder) {
        builder
            .addCase(createBrn.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(createBrn.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
            })
            .addCase(createBrn.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(createBrnPo.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(createBrnPo.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
            })
            .addCase(createBrnPo.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(createEmpty.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(createEmpty.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
            })
            .addCase(createEmpty.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(createLoan.pending, (state, action) => {
                state.createStatus = HTTP_STATUS.LOADING
            })
            .addCase(createLoan.fulfilled, (state, action) => {
                state.createStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(createLoan.rejected, (state, action) => {
                state.createStatus = HTTP_STATUS.FAILED
            })
            .addCase(fetchEmptyBottleBySales.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchEmptyBottleBySales.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.emptyBottles = action.payload
            })
            .addCase(fetchEmptyBottleBySales.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(fetchLoans.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchLoans.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.pendingLoans = action.payload
            })
            .addCase(fetchLoans.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(deleteEmpty.pending, (state, action) => {
                state.deleteStatus = HTTP_STATUS.LOADING
            })
            .addCase(deleteEmpty.fulfilled, (state, action) => {
                state.deleteStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(deleteEmpty.rejected, (state, action) => {
                state.deleteStatus = HTTP_STATUS.FAILED
            })
            .addCase(fetchBrnInvoice.pending, (state, action) => {
                state.reportStatus = HTTP_STATUS.LOADING
            })
            .addCase(fetchBrnInvoice.fulfilled, (state, action) => {
                state.reportStatus = HTTP_STATUS.SUCCEEDED
                state.report = action.payload
            })
            .addCase(fetchBrnInvoice.rejected, (state, action) => {
                state.reportStatus = HTTP_STATUS.FAILED
            })
            .addCase(fetchBrnPoInvoice.pending, (state, action) => {
                state.reportStatus = HTTP_STATUS.LOADING
            })
            .addCase(fetchBrnPoInvoice.fulfilled, (state, action) => {
                state.reportStatus = HTTP_STATUS.SUCCEEDED
                state.report = action.payload
            })
            .addCase(fetchBrnPoInvoice.rejected, (state, action) => {
                state.reportStatus = HTTP_STATUS.FAILED
            })
            .addCase(fetchStockJournal.pending, (state, action) => {
                state.reportStatus = HTTP_STATUS.LOADING
            })
            .addCase(fetchStockJournal.fulfilled, (state, action) => {
                state.reportStatus = HTTP_STATUS.SUCCEEDED
                state.report = action.payload
            })
            .addCase(fetchStockJournal.rejected, (state, action) => {
                state.reportStatus = HTTP_STATUS.FAILED
            })
    }
})

export const getBrn = (state) => state.brn.item;
export const getEmptyBottles = (state) => state.brn.emptyBottles;
export const getPendingLoans = (state) => state.brn.pendingLoans;
export const getBrnStatus = (state) => state.brn.status;
export const getBrnCreateStatus = (state) => state.brn.createStatus;
export const getBrnDeleteStatus = (state) => state.brn.deleteStatus;
export const getBrnReportStatus = (state) => state.brn.reportStatus;
export const getBrnReport = (state) => state.brn.report;

export const BrnActions = Brn.actions

export default Brn.reducer