import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const ChequeDueDates = Loadable(lazy(() => import('./ChequeDueDates/ChequeDueDates')));

const inventoryRoutes = [
    { path: '/inventory-master/cheque-due-dates',  element: <ChequeDueDates />, auth: authRoles.admin },  
    { path: '/inventory-master/cheque-due-dates/:id',  element: <ChequeDueDates />, auth: authRoles.admin }
];

export default inventoryRoutes;