import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { getProduct } from 'app/redux/reducers/ProductSlice';
import { getAuth } from 'app/redux/reducers/AuthSlice';

let RootNavigation = () => {

    const product = useSelector(getProduct);
    let auth = useSelector(getAuth);

    return (
        <>
            {auth ? product ? (
                <Navigate replace to="/dashboard/default" />
            ) : (
                <Navigate to="products/product-selection" />
                
            ) : <Navigate replace to="/session/signin" />}
        </>
    )
}

export default RootNavigation