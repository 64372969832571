import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL, HTTP_STATUS } from '../../utils/constant'
import { newAxiosInstance } from '../../../axios'
import UrlBuilder from 'app/utils/UrlBuilder'
import { generateDownloadFileName, downloadFile } from 'app/utils/utils';

const initialState = {    
    item : {
        grn_items: []        
    },
    report: null,
    status: HTTP_STATUS.IDLE,
    reportStatus: HTTP_STATUS.IDLE
}

export const fetchGrnInvoice = createAsyncThunk('grn/get-invoice', async ({id, data}, { rejectWithValue }) => {
    try {
        let url = new UrlBuilder().searchQuery(data).filter(data, 'fileType').filter(data, 'stDate').filter(data, 'enDate').getUrl();
        let responseType = data?.fileType && parseInt(data.fileType) > 0 ? 'blob' : 'text';
        const response = await newAxiosInstance.get(BASE_URL + `/api/Grn/generate-invoice/${id}${url}`,{ responseType })
        if(responseType === 'text') return response.data
        let filename = generateDownloadFileName(response.headers['content-disposition']);
        downloadFile(response.data, filename);       
        return null
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
    
})

export const fetchStockJournal = createAsyncThunk('grn/get-stock-journal', async (data, { rejectWithValue }) => {
    try {
        let url = new UrlBuilder().searchQuery(data).filter(data, 'fileType').filter(data, 'stDate').filter(data, 'enDate').filter(data, 'stockId').getUrl();
        let responseType = data?.fileType && parseInt(data.fileType) > 0 ? 'blob' : 'text';
        const response = await newAxiosInstance.get(BASE_URL + `/api/Grn/stock-journal${url}`,{ responseType })
        if(responseType === 'text') return response.data
        let filename = generateDownloadFileName(response.headers['content-disposition']);
        downloadFile(response.data, filename);       
        return null
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
    
})

export const createGrn = createAsyncThunk('grn/create', async (postdata = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.post(BASE_URL + '/api/Grn', postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const Grn = createSlice({
    name: 'grn',
    initialState,
    reducers: {
        setGrnItems: (state, action) => {
            state.item.grn_items.push(action.payload);
        },
        removeItemFromGrn: (state, action) => {
            state.item.grn_items.splice(action.payload, 1);
        },
        setResellingPrice: (state, action) => {
            state.item.grn_items[action.payload.rowIndex].reselling_price = action.payload.reselling_price
        },
        resetGrn: (state, action) => {
            return initialState;
        },
        resetGrnWithoutItem: (state, action) => {
            state.report = initialState.report
            state.status = initialState.status
            state.reportStatus = initialState.reportStatus
        }
    },
    extraReducers(builder) {
        builder
            .addCase(createGrn.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(createGrn.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
            })
            .addCase(createGrn.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(fetchGrnInvoice.pending, (state, action) => {
                state.reportStatus = HTTP_STATUS.LOADING
            })
            .addCase(fetchGrnInvoice.fulfilled, (state, action) => {
                state.reportStatus = HTTP_STATUS.SUCCEEDED
                state.report = action.payload
            })
            .addCase(fetchGrnInvoice.rejected, (state, action) => {
                state.reportStatus = HTTP_STATUS.FAILED
            })
            .addCase(fetchStockJournal.pending, (state, action) => {
                state.reportStatus = HTTP_STATUS.LOADING
            })
            .addCase(fetchStockJournal.fulfilled, (state, action) => {
                state.reportStatus = HTTP_STATUS.SUCCEEDED
                state.report = action.payload
            })
            .addCase(fetchStockJournal.rejected, (state, action) => {
                state.reportStatus = HTTP_STATUS.FAILED
            })
    }
})

export const getGrn = (state) => state.grn.item;
export const getGrnStatus = (state) => state.grn.status;
export const getGrnReportStatus = (state) => state.grn.reportStatus;
export const getGrnReport = (state) => state.grn.report;

export const { 
    resetGrn,
    setGrnItems,
    removeItemFromGrn, 
    setResellingPrice,
    resetGrnWithoutItem 
} = Grn.actions

export default Grn.reducer