import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL, HTTP_STATUS } from '../../utils/constant'
import { newAxiosInstance } from '../../../axios'
import UrlBuilder from 'app/utils/UrlBuilder'

const initialState = {
    account_details: {
        cash: null,
        cheques: null,
        credits: null,
        discount: null,
        employees: null,
        vehical: null
    },
    item_details: [],
    balance: {
        total_debits: {
            total_sales: 0,
            credit_collected: 0,
            return_ceeque_collected: 0,
            total: 0
        },
        total_credits: {
            credit: 0,
            cheque: 0,
            cash: 0,
            discount: 0,
            damege: 0,
            total: 0
        }
    },
    reps: [],
    rep_wise_sales: null,
    account_status: HTTP_STATUS.IDLE,
    item_status: HTTP_STATUS.IDLE,
    balance_status: HTTP_STATUS.IDLE,
    process_status: HTTP_STATUS.IDLE,
    fetch_report_status: HTTP_STATUS.IDLE
}

export const fetchSalesWiseAccounts = createAsyncThunk('day-end/get-sales-wise-account-details', async (saless = null, { rejectWithValue }) => {
    try {
        let url = new UrlBuilder().searchQuery(saless).filter(saless, 'queue').getUrl();
        const response = await newAxiosInstance.get(BASE_URL + `/api/DayEnd/get-acounts-sales-wise/${saless?.id}${url}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchSalesWiseItems = createAsyncThunk('day-end/get-sales-wise-item-details', async (saless = null, { rejectWithValue }) => {
    try {
        let url = new UrlBuilder().searchQuery(saless).filter(saless, 'queue').getUrl();
        const response = await newAxiosInstance.get(BASE_URL + `/api/DayEnd/get-items-sales-wise/${saless?.id}${url}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchBalanceSalesWise = createAsyncThunk('day-end/get-balance-sales-wise', async (saless = null, { rejectWithValue }) => {
    try {
        let url = new UrlBuilder().searchQuery(saless).filter(saless, 'queue').getUrl();
        const response = await newAxiosInstance.get(BASE_URL + `/api/DayEnd/get-balance-sales-wise/${saless?.id}${url}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchRepsInSale = createAsyncThunk('day-end/get-reps-in-sale', async (data = null, { rejectWithValue }) => {
    try {
        let url = new UrlBuilder().searchQuery(data).filter(data, 'dateTime').getUrl();
        const response = await newAxiosInstance.get(BASE_URL + `/api/DayEnd/get-reps-in-sale${url}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const processDayEndSalesWise = createAsyncThunk('day-end/process-day-end-sales-wise', async (id = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.put(BASE_URL + `/api/DayEnd/process-sales-summary/${id}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const processDayEndSalesWiseReportView = createAsyncThunk('day-end/process-day-end-sales-wise-report-view', async ({id, data}, { rejectWithValue }) => {
    try {
        let url = new UrlBuilder().searchQuery(data).filter(data, 'fileType').filter(data, 'queue').getUrl();
        const response = await newAxiosInstance.get(BASE_URL + `/api/DayEnd/process-dayend-rep-wise-report/${id+url}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const Dayend = createSlice({
    name: 'dayend',
    initialState,
    reducers: {
        resetState: (state, action) => {
            return initialState;
        },
        resetSalesData: (state, action) => {
            state.account_details = initialState.account_details
            state.balance = initialState.balance
            state.item_details = initialState.item_details
        },
        resetRef: (state, action) => {
            state.reps = []
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchSalesWiseAccounts.pending, (state, action) => {
                state.account_status = HTTP_STATUS.LOADING
            })
            .addCase(fetchSalesWiseAccounts.fulfilled, (state, action) => {
                state.account_status = HTTP_STATUS.SUCCEEDED
                state.account_details = action.payload
            })
            .addCase(fetchSalesWiseAccounts.rejected, (state, action) => {
                state.account_status = HTTP_STATUS.FAILED
            })
            .addCase(fetchSalesWiseItems.pending, (state, action) => {
                state.item_status = HTTP_STATUS.LOADING
            })
            .addCase(fetchSalesWiseItems.fulfilled, (state, action) => {
                state.item_status = HTTP_STATUS.SUCCEEDED
                state.item_details = action.payload
            })
            .addCase(fetchSalesWiseItems.rejected, (state, action) => {
                state.item_status = HTTP_STATUS.FAILED
            })
            .addCase(fetchBalanceSalesWise.pending, (state, action) => {
                state.balance_status = HTTP_STATUS.LOADING
            })
            .addCase(fetchBalanceSalesWise.fulfilled, (state, action) => {
                state.balance_status = HTTP_STATUS.SUCCEEDED
                state.balance = action.payload
            })
            .addCase(fetchBalanceSalesWise.rejected, (state, action) => {
                state.balance_status = HTTP_STATUS.FAILED
            })
            .addCase(processDayEndSalesWise.pending, (state, action) => {
                state.process_status = HTTP_STATUS.LOADING
            })
            .addCase(processDayEndSalesWise.fulfilled, (state, action) => {
                state.process_status = HTTP_STATUS.SUCCEEDED
            })
            .addCase(processDayEndSalesWise.rejected, (state, action) => {
                state.process_status = HTTP_STATUS.FAILED
            })
            .addCase(fetchRepsInSale.fulfilled, (state, action) => {
                state.reps = action.payload
            })
            .addCase(processDayEndSalesWiseReportView.pending, (state, action) => {
                state.fetch_report_status = HTTP_STATUS.LOADING
            })
            .addCase(processDayEndSalesWiseReportView.fulfilled, (state, action) => {
                state.fetch_report_status = HTTP_STATUS.SUCCEEDED
                state.rep_wise_sales = action.payload
            })
            .addCase(processDayEndSalesWiseReportView.rejected, (state, action) => {
                state.fetch_report_status = HTTP_STATUS.FAILED
            })
    }
})

export const getAccountDetails = (state) => state.dayend.account_details
export const getItemDetails = (state) => state.dayend.item_details
export const getBalanceSheet = (state) => state.dayend.balance
export const getReps = (state) => state.dayend.reps
export const getRepWiseSales = (state) => state.dayend.rep_wise_sales
export const getItemstatus = (state) => state.dayend.item_status
export const getDayendAccountWiseStatus = (state) => state.dayend.account_status;
export const getBalanceWiseStatus = (state) => state.dayend.balance_status;
export const getProcessStatus = (state) => state.dayend.process_status;
export const getReportStatus = (state) => state.dayend.fetch_report_status;

export const dayendActions = Dayend.actions

export default Dayend.reducer