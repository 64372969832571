import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL, HTTP_STATUS } from '../../utils/constant'
import { newAxiosInstance } from '../../../axios'
import UrlBuilder from 'app/utils/UrlBuilder'

const initialState = {    
    expenses : [],
    status: HTTP_STATUS.IDLE,
    createStatus: HTTP_STATUS.IDLE,
    removeStatus: HTTP_STATUS.IDLE
}

export const fetchpendingExpense = createAsyncThunk('expense/get-expenses', async (data = null, { rejectWithValue }) => {
    let url = new UrlBuilder().searchQuery(data).filter(data, 'fromDate').filter(data, 'toDate').filter(data, 'payable').filter(data, 'id').filter(data, 'payables').getUrl();
    try {
        const response = await newAxiosInstance.get(BASE_URL + `/api/Expense${url}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchExpenses = createAsyncThunk('expense/get-expence', async (data = null, { rejectWithValue }) => {
    try {
        let url = new UrlBuilder().searchQuery(data).filter(data, 'acc_type').getUrl();
        const response = await newAxiosInstance.get(BASE_URL + '/api/Expense' + url ) 
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const createExpense = createAsyncThunk('expense/create-expence', async (data = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.post(BASE_URL + '/api/Expense', data) 
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const payPayableExpense = createAsyncThunk('expense/pay-payables', async (data = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.put(BASE_URL + '/api/Expense/'+ data.id, data.body) 
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const removeExpense = createAsyncThunk('expense/remove-expence', async (id = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.delete(BASE_URL + `/api/Expense/${id}`) 
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const Expense = createSlice({
    name: 'expense',
    initialState,
    reducers: {
        resetState: (state, action) => {
            return initialState;
        },
        resetExpense: (state, action) => {
            state.expenses = []
            return state;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchpendingExpense.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchpendingExpense.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.expenses = action.payload
            })
            .addCase(fetchpendingExpense.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(createExpense.pending, (state, action) => {
                state.createStatus = HTTP_STATUS.LOADING
            })
            .addCase(createExpense.fulfilled, (state, action) => {
                state.createStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(createExpense.rejected, (state, action) => {
                state.createStatus = HTTP_STATUS.FAILED
            })
            .addCase(removeExpense.pending, (state, action) => {
                state.removeStatus = HTTP_STATUS.LOADING
            })
            .addCase(removeExpense.fulfilled, (state, action) => {
                state.removeStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(removeExpense.rejected, (state, action) => {
                state.removeStatus = HTTP_STATUS.FAILED
            })
            .addCase(payPayableExpense.pending, (state, action) => {
                state.createStatus = HTTP_STATUS.LOADING
            })
            .addCase(payPayableExpense.fulfilled, (state, action) => {
                state.createStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(payPayableExpense.rejected, (state, action) => {
                state.createStatus = HTTP_STATUS.FAILED
            })
    }
})

export const getExpenses = (state) => state.expense.expenses;
export const getExpenseStatus = (state) => state.expense.status;
export const getcreateStatus = (state) => state.expense.createStatus;
export const getRemoveStatus = (state) => state.expense.removeStatus;

export const expenseAction = Expense.actions

export default Expense.reducer