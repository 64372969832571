const rolePriority = {
    1: "User",
    2: "Admin",
    3: "SuperAdmin"
}

export const authRoles = {
    sa: [rolePriority[3]], // Only Super Admin has access
    admin: [rolePriority[3], rolePriority[2]], // Only SA & Admin has access
    guest: [rolePriority[3], rolePriority[2], rolePriority[1] ], // Everyone has access
}

export const roleProrityMap = rolePriority
