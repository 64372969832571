import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    open: false, 
    text: '', 
    severity: 'info',
    vertical: 'top',
    horizontal: 'center',
}

export const Alert = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        resetAlert: (state, action) => {
            state = {...initialState}
            state.severity = action.payload.severity
            return state
        },
        setAlert: (state, action) => {
            state.open = action.payload.open
            state.text = action.payload.text
            state.severity = action.payload.severity
            state.vertical = action.payload?.vertical ? action.payload.vertical : initialState.vertical
            state.horizontal = action.payload?.horizontal ? action.payload?.horizontal : initialState.horizontal
            return state;
        },
        resetToInitial: (state, action) => {
            return initialState;
        }
    }
})

export const getAlert = (state) => state.alert;

export const { resetAlert, setAlert, resetToInitial } = Alert.actions

export default Alert.reducer