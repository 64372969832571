import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL, HTTP_STATUS } from '../../utils/constant'
import { newAxiosInstance } from '../../../axios'
import UrlBuilder from 'app/utils/UrlBuilder'
import { generateDownloadFileName, downloadFile } from 'app/utils/utils';

const initialState = {
    productCategories: [],    
    items : [],
    bottleAllowedList: [],
    stockReport: null,
    expireReport: null,
    status: HTTP_STATUS.IDLE,
    createItemStatus: HTTP_STATUS.IDLE,
    deleteStatus: HTTP_STATUS.IDLE
}

export const fetchItemByMainCategoryId = createAsyncThunk('item/get-item-by-main-category', async (data, { rejectWithValue }) => {
    try {
        let search = data.search ? `?searchQuery=${data.search}` : '';
        let pages = data.page && data.pageSize ? `?Page=${data.page}&PageSize=${data.pageSize}` : '';
        let query = search + pages;
        const response = await newAxiosInstance.get(`${BASE_URL}/api/Stock/get-item-by-main-category/${data.itemId}${query}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchStockReport = createAsyncThunk('item/get-stock', async (data, { rejectWithValue }) => {
    try {
        let url = new UrlBuilder().searchQuery(data).filter(data, 'fileType').getUrl();
        let responseType = data?.fileType && parseInt(data.fileType) > 0 ? 'blob' : 'text';
        const response = await newAxiosInstance.get(BASE_URL + `/api/Stock/get-stock${url}`,{ responseType })
        if(responseType === 'text') return response.data
        let filename = generateDownloadFileName(response.headers['content-disposition']);
        downloadFile(response.data, filename);       
        return null
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
    
})

export const fetchExpireReport = createAsyncThunk('item/get-expire-report', async (data = null, { rejectWithValue }) => {
    try {
        let url = new UrlBuilder().filter(data, 'fileType').filter(data, 'stDate').filter(data, 'enDate').getUrl();
        let responseType = data?.fileType && parseInt(data.fileType) > 0 ? 'blob' : 'text';
        const response = await newAxiosInstance.get(BASE_URL + `/api/Stock/stock-expire${url}`,{ responseType })
        if(responseType === 'text') return response.data
        let filename = generateDownloadFileName(response.headers['content-disposition']);
        downloadFile(response.data, filename);       
        return null
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchmainItemCategories = createAsyncThunk('item/get-product-list', async (data = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.get(BASE_URL + '/api/Stock/get-product-list')
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchmainItemBottleAllowed = createAsyncThunk('item/get-product-list-bottle-allowed', async (data = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.get(BASE_URL + '/api/Stock/get-bottle-stock-allowed')
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchProductsCategories = createAsyncThunk('item/get-products-categories', async (itemId = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.get(BASE_URL + `/api/Stock/get-products-categories/${itemId}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const deleteItem = createAsyncThunk('item/remove-item', async (id, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.delete(BASE_URL + '/api/Stock/remove/' + id)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const deleteItemRecord = createAsyncThunk('item/remove-item-record', async (id, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.delete(BASE_URL + '/api/Stock/' + id)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const setItem = createAsyncThunk('item/create', async (postdata, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.post(BASE_URL + '/api/Stock', postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const updateItem = createAsyncThunk('item/update', async ({id, postData}, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.put(BASE_URL + '/api/Stock/item/'+id, postData)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const tagBottleStock = createAsyncThunk('item/update-bottle-stock', async (postData, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.post(BASE_URL + '/api/Stock/tag-bottle-stock', postData)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const setItemCategory = createAsyncThunk('item-category/create', async (postdata, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.post(BASE_URL + '/api/Stock/create-item', postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const updateItemCategory = createAsyncThunk('item-category/update', async (postdata, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.put(BASE_URL + '/api/Stock/update-item/'+postdata.id, postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const Item = createSlice({
    name: 'item',
    initialState,
    reducers: {
        resetStatus: (state, action) => {
            state.status = HTTP_STATUS.IDLE
            return state;
        },
        resetCreateStatus: (state, action) => {
            state.status = HTTP_STATUS.IDLE
            return state;
        },
        resetDeleteStatus: (state, action) => {
            state.deleteStatus = HTTP_STATUS.IDLE
            return state;
        },
        resetItem: (state, action) => {
            return initialState
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchItemByMainCategoryId.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchItemByMainCategoryId.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.items = action.payload.map(item => {
                    return {...item, items: null, childItems: null, parent: item.parent ? {...item.parent, childItems: null }: null, mainItem: null }
                })
            })
            .addCase(fetchItemByMainCategoryId.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(fetchProductsCategories.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchProductsCategories.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.productCategories = action.payload.map(item => {
                    return {...item, items: null }
                })
            })
            .addCase(fetchProductsCategories.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(setItem.pending, (state, action) => {
                state.createItemStatus = HTTP_STATUS.LOADING
            })
            .addCase(setItem.fulfilled, (state, action) => {
                state.createItemStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(setItem.rejected, (state, action) => {
                state.createItemStatus = HTTP_STATUS.FAILED
            })
            .addCase(updateItem.pending, (state, action) => {
                state.createItemStatus = HTTP_STATUS.LOADING
            })
            .addCase(updateItem.fulfilled, (state, action) => {
                state.createItemStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(updateItem.rejected, (state, action) => {
                state.createItemStatus = HTTP_STATUS.FAILED
            })
            .addCase(setItemCategory.pending, (state, action) => {
                state.createItemStatus = HTTP_STATUS.LOADING
            })
            .addCase(setItemCategory.fulfilled, (state, action) => {
                state.createItemStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(setItemCategory.rejected, (state, action) => {
                state.createItemStatus = HTTP_STATUS.FAILED
            })
            .addCase(updateItemCategory.pending, (state, action) => {
                state.createItemStatus = HTTP_STATUS.LOADING
            })
            .addCase(updateItemCategory.fulfilled, (state, action) => {
                state.createItemStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(updateItemCategory.rejected, (state, action) => {
                state.createItemStatus = HTTP_STATUS.FAILED
            })
            .addCase(deleteItem.pending, (state, action) => {
                state.deleteStatus = HTTP_STATUS.LOADING
            })
            .addCase(deleteItem.fulfilled, (state, action) => {
                state.deleteStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(deleteItem.rejected, (state, action) => {
                state.deleteStatus = HTTP_STATUS.FAILED
            })
            .addCase(deleteItemRecord.pending, (state, action) => {
                state.deleteStatus = HTTP_STATUS.LOADING
            })
            .addCase(deleteItemRecord.fulfilled, (state, action) => {
                state.deleteStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(deleteItemRecord.rejected, (state, action) => {
                state.deleteStatus = HTTP_STATUS.FAILED
            })
            .addCase(fetchStockReport.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchStockReport.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.stockReport = action.payload
            })
            .addCase(fetchStockReport.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(tagBottleStock.pending, (state, action) => {
                state.createItemStatus = HTTP_STATUS.LOADING
            })
            .addCase(tagBottleStock.fulfilled, (state, action) => {
                state.createItemStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(tagBottleStock.rejected, (state, action) => {
                state.createItemStatus = HTTP_STATUS.FAILED
            })
            .addCase(fetchmainItemBottleAllowed.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchmainItemBottleAllowed.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.bottleAllowedList = action.payload
            })
            .addCase(fetchmainItemBottleAllowed.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(fetchExpireReport.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchExpireReport.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.expireReport = action.payload
            })
            .addCase(fetchExpireReport.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
    }
})

export const getItems = (state) => state.items.items;
export const getBottleAllowedList = (state) => state.items.bottleAllowedList;
export const getStockReport = (state) => state.items.stockReport;
export const getExpireReport = (state) => state.items.expireReport;
export const getProductsCategories = (state) => state.items.productCategories;
export const getItemStatus = (state) => state.items.status;
export const getItemCreateStatus = (state) => state.items.createItemStatus;
export const getItemDeleteStatus = (state) => state.items.deleteStatus;

export const { resetStatus, resetDeleteStatus, resetCreateStatus, resetItem } = Item.actions

export default Item.reducer