import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL, HTTP_STATUS } from '../../utils/constant'
import { newAxiosInstance } from '../../../axios'
import UrlBuilder from 'app/utils/UrlBuilder'
import { generateDownloadFileName, downloadFile } from 'app/utils/utils';

const initialState = {    
    discounts : [],
    discountReport: null,
    status: HTTP_STATUS.IDLE,
    deleteStatus: HTTP_STATUS.IDLE
}

export const fetchDiscountsBySales = createAsyncThunk('Discount/get-Discount-by-sales', async (salesId, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.get(BASE_URL + `/api/Discount/get-by-sales/${salesId}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchDiscountReport = createAsyncThunk('discount/get-discount-report', async (data = null, { rejectWithValue }) => {
    try {
        let url = new UrlBuilder().filter(data, 'fileType').filter(data, 'stDate').filter(data, 'enDate').filter(data, 'repId').getUrl();
        let responseType = data?.fileType && parseInt(data.fileType) > 0 ? 'blob' : 'text';
        const response = await newAxiosInstance.get(BASE_URL + `/api/Discount/discount-report${url}`,{ responseType })
        if(responseType === 'text') return response.data
        let filename = generateDownloadFileName(response.headers['content-disposition']);
        downloadFile(response.data, filename);       
        return null
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const createDiscounts = createAsyncThunk('Discount/create', async (postdata = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.post(BASE_URL + '/api/Discount', postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const removeDiscounts = createAsyncThunk('Discount/remove', async (id = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.delete(BASE_URL + `/api/Discount/${id}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})



export const Discount = createSlice({
    name: 'discount',
    initialState,
    reducers: {
        resetState: (state, action) => {
            return initialState;
        },
        resetDiscounts: (state, action) => {
            state.discounts = [];
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchDiscountsBySales.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchDiscountsBySales.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.discounts = action.payload
            })
            .addCase(fetchDiscountsBySales.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(removeDiscounts.pending, (state, action) => {
                state.deleteStatus = HTTP_STATUS.LOADING
            })
            .addCase(removeDiscounts.fulfilled, (state, action) => {
                state.deleteStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(removeDiscounts.rejected, (state, action) => {
                state.deleteStatus = HTTP_STATUS.FAILED
            })
            .addCase(fetchDiscountReport.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchDiscountReport.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.discountReport = action.payload
            })
            .addCase(fetchDiscountReport.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            
    }
})

export const getDiscounts = (state) => state.discount.discounts;
export const getDiscountReport = (state) => state.discount.discountReport;
export const getDiscountsStatus = (state) => state.discount.status;
export const getDeleteDiscountstatus = (state) => state.discount.deleteStatus;
export const discountActions = Discount.actions

export default Discount.reducer