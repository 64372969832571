import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const GrnReport = Loadable(lazy(() => import('./GrnReport/GrnReport')));
const BrnReport = Loadable(lazy(() => import('./BrnReport/BrnReport')));
const StockJournal = Loadable(lazy(() => import('./StockJournal/StockJournal')));
const BrnStockJournal = Loadable(lazy(() => import('./StockJournal/BrnStockJournal')));
const StockReport = Loadable(lazy(() => import('./StockReport/StockReport')));

const inventoryRoutes = [
  { path: '/inventory/grns/invoice', element: <GrnReport />, auth: authRoles.guest  },
  { path: '/inventory/brns/invoice', element: <BrnReport />, auth: authRoles.guest, routeRestrict: true },
  { path: '/inventory/stocks/journal-report', element: <StockJournal />, auth: authRoles.guest  },
  { path: '/inventory/brn-stocks/journal-report', element: <BrnStockJournal />, auth: authRoles.guest, routeRestrict: true },
  { path: '/inventory/stock/report', element: <StockReport />, auth: authRoles.guest  }
];

export default inventoryRoutes;