import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const PendingCredits = Loadable(lazy(() => import('./PendingCredits/PendingCredits')));
const PendingCheques = Loadable(lazy(() => import('./PendingCheques/PendingCheques')));
const DayendSalesWise = Loadable(lazy(() => import('./DayendSalesWise/DayendSalesWise')));
const DayendDetailSalesWise = Loadable(lazy(() => import('./DayendSalesWise/DayendDetailSalesWise')));
const CreditReport = Loadable(lazy(() => import('./CreditReport/CreditReport')));
const BankReport = Loadable(lazy(() => import('./BankReport/BankReport')));
const ChequeReport = Loadable(lazy(() => import('./ChequeReport/ChequeReport')));
const CashReport = Loadable(lazy(() => import('./cashReport/CashReport')));
const DiscountReport = Loadable(lazy(() => import('./discountReport/DiscountReport')));
const PendingBottleLoan = Loadable(lazy(() => import('./PendingBottleLoanReport/PendingBottleLoan')));
const BottleLoanReport = Loadable(lazy(() => import('./BottleLoanReport/BottleLoanReport')))
const BottleReport = Loadable(lazy(() => import('./BottleReport/BottleReport')))
const BrnPoReport = Loadable(lazy(() => import('./BrnPoReport/BrnPoReport')))
const BottleLoanInvoice = Loadable(lazy(() => import('./BottleLoanInvoice/BottleLoanInvoice')))

const inventoryRoutes = [
  { path: '/accounts/credits/pending-credit-report', element: <PendingCredits />, auth: authRoles.guest  },
  { path: '/accounts/cheques/pending-cheque-report', element: <PendingCheques />, auth: authRoles.guest  },
  { path: '/accounts/credits/credit-report', element: <CreditReport />, auth: authRoles.guest },
  { path: '/accounts/banks/bank-report', element: <BankReport />, auth: authRoles.guest },
  { path: '/accounts/cash/cash-report', element: <CashReport />, auth: authRoles.guest },
  { path: '/accounts/discount/discount-report', element: <DiscountReport />, auth: authRoles.guest },
  { path: '/accounts/cheques/cheque-report', element: <ChequeReport />, auth: authRoles.guest },
  { path: '/accounts/dayend-rep-wise', element: <DayendSalesWise />, auth: authRoles.guest },
  { path: '/accounts/dayend-detail-rep-wise', element: <DayendDetailSalesWise />, auth: authRoles.guest },
  { path: '/accounts/bottle-loans/pending-bottle-loan-report', element: <PendingBottleLoan />, auth: authRoles.guest, routeRestrict: true },
  { path: '/accounts/bottle-loans/bottle-loan-report', element: <BottleLoanReport />, auth: authRoles.guest, routeRestrict: true },
  { path: '/accounts/bottle-loans/bottle-report', element: <BottleReport />, auth: authRoles.guest, routeRestrict: true },
  { path: '/accounts/bottle/brn-po-invoice', element: <BrnPoReport />, auth: authRoles.guest, routeRestrict: true },
  { path: '/accounts/pending-bottle-loan-management/invoice/:id', element: <BottleLoanInvoice />, auth: authRoles.guest, routeRestrict: true },
];

export default inventoryRoutes;