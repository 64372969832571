import '../fake-db';
import { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { MatxTheme } from './components';
import { AuthProvider } from './contexts/JWTAuthContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { DialogProvider } from './contexts/DialogContext';
import { useSelector, useDispatch } from "react-redux";
import { fetchRefresh, getAuth, resetAuth } from 'app/redux/reducers/AuthSlice';
import { newAxiosInstance } from '../axios'
import PositionedSnackbar from './components/PositionedSnackbar';
import { getProduct } from 'app/redux/reducers/ProductSlice';
import useSyncTokensAcrossTabs from 'app/hooks/useSyncTokensAcrossTabs';
import { fetchAppConfig, getConfig } from './redux/reducers/AppConfigs';

import routes from './routes';
import ConfirmationDialog from 'app/components/ConfirmationDialog';

let isRefreshing = false;
let refreshSubscribers = [];

// Function to add failed request to the queue
const subscribeTokenRefresh = (callback) => {
  refreshSubscribers.push(callback);
};

// Notify all subscribers with the new token
const onRefreshed = (newAccessToken) => {
  refreshSubscribers.forEach((callback) => callback(newAccessToken));
  refreshSubscribers = [];
};

const App = () => {
  const content = useRoutes(routes);
  const product = useSelector(getProduct);
  const auth = useSelector(getAuth);
  const appSetting = useSelector(getConfig);
  const dispatch = useDispatch();
  useSyncTokensAcrossTabs();

  let preventBrowserRefresh = (e) => {
    e.preventDefault()
    e.returnValue = ''
  }

  useEffect(() => {
    const requestIntercepter = newAxiosInstance.interceptors.request.use(
      (config) => {
        if (!config?.headers?.common?.Authorization && auth?.token)
          config.headers.common.Authorization = `Bearer ${auth.token}`
          config.headers.common["X-agency"] = product?.id ?? 0;
        return config;
      },
      (error) => Promise.reject(error)
    );
    const responseIntercepter = newAxiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error?.config;        

        if (error?.response?.status === 401 && !originalRequest?.sent && auth !== null) {

          originalRequest.sent = true;

          // If already refreshing the token, add the request to the queue
          if (isRefreshing) {
            return new Promise((resolve) => {
              subscribeTokenRefresh((token) => {
                originalRequest.headers['Authorization'] = `Bearer ${token}`;
                originalRequest.headers["X-agency"] = product?.id ?? 0;
                resolve(newAxiosInstance(originalRequest));
              });
            });
          }

          isRefreshing = true;
          
          return dispatch(fetchRefresh({ refreshToken: auth.refreshToken }))
            .unwrap()
            .then((resp) => {
              
              originalRequest.headers["Authorization"] = `Bearer ${resp.token}`
              originalRequest.headers["X-agency"] = product?.id ?? 0;

              // Notify all subscribers that the token has been refreshed
              onRefreshed(resp.token);

              // Retry the original request with the new token
              return newAxiosInstance(originalRequest).catch(ex => {
                  console.error(ex);                      
              });
            })
            .catch((ex) => {
              dispatch(resetAuth());
              console.error(ex); 
            })
            .finally(() => {
              isRefreshing = false; // Reset the refreshing flag
            })
        }
        return Promise.reject(error);
      }
    );
    return () => {
      newAxiosInstance.interceptors.request.eject(requestIntercepter);
      newAxiosInstance.interceptors.response.eject(responseIntercepter);
    };
  }, [auth, product])

  useEffect(() => {
    window.addEventListener('beforeunload', preventBrowserRefresh)
    if(appSetting === null) {
      dispatch(fetchAppConfig());
    }else{
      document.title = appSetting['app-name'] + ' | Redistribution Management System';
    }
    return () => {
      window.removeEventListener('beforeunload', preventBrowserRefresh)
    }
  }, [])
  
  return (    
      <SettingsProvider>
          <MatxTheme>
            <DialogProvider>
              <AuthProvider>{content}</AuthProvider>
              <ConfirmationDialog />
            </DialogProvider>
            <PositionedSnackbar />
          </MatxTheme>
      </SettingsProvider>      
  );
};

export default App;
