import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL, HTTP_STATUS } from '../../utils/constant'
import { newAxiosInstance } from '../../../axios'
import UrlBuilder from 'app/utils/UrlBuilder'

const initialState = {    
    banks : [],
    activatedBanks: [],
    cash_types: [],
    status: HTTP_STATUS.IDLE,
    deleteStatus: HTTP_STATUS.IDLE
}

export const fetchBanks = createAsyncThunk('bank/get-all', async (data = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.get(BASE_URL + '/api/Bank') 
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchActivatedBanks = createAsyncThunk('bank/get-activated', async (data = null, { rejectWithValue }) => {
    try {
        let url = new UrlBuilder().searchQuery(data).filter(data, 'activate').getUrl();
        const response = await newAxiosInstance.get(BASE_URL + '/api/Bank' + url) 
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchCashTypes = createAsyncThunk('bank/cash-type/get-all', async (postdata = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.get(BASE_URL + '/api/Bank/cash-types')
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const createBanks = createAsyncThunk('bank/create', async (postdata, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.post(BASE_URL + '/api/Bank', postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const updateBanks = createAsyncThunk('bank/update', async (postdata, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.put(BASE_URL + `/api/Bank/${postdata.id}`, postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const deleteBanks = createAsyncThunk('bank/delete', async (id, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.delete(BASE_URL + `/api/Bank/${id}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const Banks = createSlice({
    name: 'bank',
    initialState,
    reducers: {
        resetState: (state, action) => {
            return initialState;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchBanks.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchBanks.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.banks = action.payload
            })
            .addCase(fetchBanks.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(fetchActivatedBanks.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchActivatedBanks.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.activatedBanks = action.payload
            })
            .addCase(fetchActivatedBanks.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(fetchCashTypes.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchCashTypes.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.cash_types = action.payload
            })
            .addCase(fetchCashTypes.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(deleteBanks.pending, (state, action) => {
                state.deleteStatus = HTTP_STATUS.LOADING
            })
            .addCase(deleteBanks.fulfilled, (state, action) => {
                state.deleteStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(deleteBanks.rejected, (state, action) => {
                state.deleteStatus = HTTP_STATUS.FAILED
            })
    }
})

export const getBanks = (state) => state.bank.banks;
export const getActivatedBanks = (state) => state.bank.activatedBanks;
export const getCashTypes = (state) => state.bank.cash_types;
export const getBanksStatus = (state) => state.bank.status;
export const getBanksDeleteStatus = (state) => state.bank.deleteStatus;

export const bankActions = Banks.actions

export default Banks.reducer