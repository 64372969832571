import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const User = Loadable(lazy(() => import('./User/User')));
const Register = lazy(() => import('./User/Register/Register'));
const PasswordChange = lazy(() => import('./User/PasswordChange/PasswordChange'));
const LinkItem = lazy(() => import('./User/LinkItem/LinkItem'));

const stockRoutes = [
    { 
      path: '/user/account', 
      element: <User />,
      auth: authRoles.sa, 
      children: [
        { path: 'register', element: <Register />, auth: authRoles.sa  },
        { path: 'update-user/:id', element: <Register />, auth: authRoles.sa  },
        { path: 'reset-password/:id', element: <PasswordChange />, auth: authRoles.sa  },
        { path: 'link-items/:id', element: <LinkItem />, auth: authRoles.sa  },
      ]},  
];

export default stockRoutes;