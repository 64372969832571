import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const ProductSelect = Loadable(lazy(() => import('./ProductSelect/ProductSelect')));

const productRoutes = [
  { path: '/products/product-selection', element: <ProductSelect /> }
];

export default productRoutes;
