import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const DailyRoot = Loadable(lazy(() => import('./DailyRoot/DailyRoot')));
const Vehical = Loadable(lazy(() => import('./Vehical/Vehical')));

const stockRoutes = [
    { path: '/sales/master/roots',  element: <DailyRoot />, auth: authRoles.admin },  
    { path: '/sales/master/roots/:id',  element: <DailyRoot />, auth: authRoles.admin },  
    { path: '/sales/master/vehicals',  element: <Vehical />, auth: authRoles.sa },
    { path: '/sales/master/vehicals/:id',  element: <Vehical />, auth: authRoles.sa },
];

export default stockRoutes;