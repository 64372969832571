import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL, HTTP_STATUS } from '../../utils/constant'
import { newAxiosInstance } from '../../../axios'
import UrlBuilder from 'app/utils/UrlBuilder'
import { generateDownloadFileName, downloadFile } from 'app/utils/utils';

const initialState = {    
    sales : [],
    salesItems: [],
    salesStaff: [],
    salesReport: null,
    loadingReport: null,
    itemWiseSalesReport: null,
    status: HTTP_STATUS.IDLE,
    createStatus: HTTP_STATUS.IDLE,
    deleteItemStatus: HTTP_STATUS.IDLE,
    addAdditionalItemStatus: HTTP_STATUS.IDLE,
}

export const fetchSales = createAsyncThunk('sales/get-all', async (data = null, { rejectWithValue }) => {
    try {
        data = data === null ? { Page: 1, PageSize: 10 } : data;
        let url = new UrlBuilder().searchQuery(data).filter(data, 'Agency_id').setPage(data).setPageSize(data).getUrl();
        const response = await newAxiosInstance.get(BASE_URL + `/api/DailyVehicalRoutin${url}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchSalesById = createAsyncThunk('sales/get-by-id', async (id, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.get(BASE_URL + `/api/DailyVehicalRoutin/${id}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchItemsbySales = createAsyncThunk('sales/get-sales-item', async (data, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.get(BASE_URL + `/api/DailyVehicalRoutin/get-stock-by-daily-root/${data.id}?gas_out=${data.gas_out}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchDailyRootStaffs = createAsyncThunk('sales/get-staff', async (id, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.get(BASE_URL + `/api/DailyVehicalRoutin/get-staff-by-daily-root/${id}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchSalesReport = createAsyncThunk('sales/get-sales-report', async (data = null, { rejectWithValue }) => {
    try {
        let url = new UrlBuilder().filter(data, 'fileType').filter(data, 'stDate').filter(data, 'enDate').filter(data, 'repId')
                        .filter(data, 'stockId').getUrl();
        let responseType = data?.fileType && parseInt(data.fileType) > 0 ? 'blob' : 'text';
        const response = await newAxiosInstance.get(BASE_URL + `/api/DailyVehicalRoutin/sales-report${url}`,{ responseType })
        if(responseType === 'text') return response.data
        let filename = generateDownloadFileName(response.headers['content-disposition']);
        downloadFile(response.data, filename);       
        return null
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchItemWiseSalesReport = createAsyncThunk('sales/get-item-wise-sales-report', async (data = null, { rejectWithValue }) => {
    try {
        let url = new UrlBuilder().filter(data, 'fileType').filter(data, 'stDate').filter(data, 'enDate').getUrl();
        let responseType = data?.fileType && parseInt(data.fileType) > 0 ? 'blob' : 'text';
        const response = await newAxiosInstance.get(BASE_URL + `/api/DailyVehicalRoutin/item-wise-sales-report${url}`,{ responseType })
        if(responseType === 'text') return response.data
        let filename = generateDownloadFileName(response.headers['content-disposition']);
        downloadFile(response.data, filename);       
        return null
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchLoadingReport = createAsyncThunk('sales/get-loading-report', async (data = null, { rejectWithValue }) => {
    try {
        let url = new UrlBuilder().filter(data, 'fileType').filter(data, 'stDate').filter(data, 'enDate').filter(data, 'repId')
                        .filter(data, 'vehicalId').getUrl();
        let responseType = data?.fileType && parseInt(data.fileType) > 0 ? 'blob' : 'text';
        const response = await newAxiosInstance.get(BASE_URL + `/api/DailyVehicalRoutin/loading-unloading${url}`,{ responseType })
        if(responseType === 'text') return response.data
        let filename = generateDownloadFileName(response.headers['content-disposition']);
        downloadFile(response.data, filename);       
        return null
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const createSales = createAsyncThunk('sales/create', async (postdata = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.post(BASE_URL + '/api/DailyVehicalRoutin', postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const createGasoutSales = createAsyncThunk('sales/create/gas-out', async (postdata = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.post(BASE_URL + `/api/DailyVehicalRoutin/add-gasout-items-by-sales/${postdata.id}`, postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const completeUnload = createAsyncThunk('sales/unload/complete', async (unload = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.put(BASE_URL + `/api/DailyVehicalRoutin/${unload.id}`, unload)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const addAditionalItem = createAsyncThunk('sales/add-additional-item', async ({id, postdata}, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.post(BASE_URL + `/api/DailyVehicalRoutin/add-items-on-loading/${id}`, postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const deleteSalesItem = createAsyncThunk('sales/delete/item', async (id, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.delete(BASE_URL + `/api/DailyVehicalRoutin/dalete-sales-item/${id}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const deleteSales = createAsyncThunk('sales/delete', async (id, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.delete(BASE_URL + `/api/DailyVehicalRoutin/${id}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const Sales = createSlice({
    name: 'sales',
    initialState,
    reducers: {
        resetState: (state, action) => {
            return initialState;
        },
        removeItemFromSales: (state, action) => {
            state.salesItems =  state.salesItems.filter(item => item?.id !== action.payload)
            return state
        },
        changeSales : (state, action) => {
            state.sales = action.payload
        },
        resetSalesItems : (state, action) => {
            state.salesItems = initialState.salesItems
        },
        resetCreactStatus: (state, action) => {
            state.createStatus = initialState.createStatus
        },
        resetSalesStaff: (state, action) => {
            state.salesStaff = []
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchSales.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchSales.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.sales = action.payload
            })
            .addCase(fetchSales.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(createSales.pending, (state, action) => {
                state.createStatus = HTTP_STATUS.LOADING
            })
            .addCase(createSales.fulfilled, (state, action) => {
                state.createStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(createSales.rejected, (state, action) => {
                state.createStatus = HTTP_STATUS.FAILED
            })
            .addCase(completeUnload.pending, (state, action) => {
                state.createStatus = HTTP_STATUS.LOADING
            })
            .addCase(completeUnload.fulfilled, (state, action) => {
                state.createStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(completeUnload.rejected, (state, action) => {
                state.createStatus = HTTP_STATUS.FAILED
            })
            .addCase(fetchItemsbySales.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchItemsbySales.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.salesItems = action.payload
            })
            .addCase(fetchItemsbySales.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(fetchDailyRootStaffs.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchDailyRootStaffs.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.salesStaff = action.payload
            })
            .addCase(fetchDailyRootStaffs.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(deleteSalesItem.pending, (state, action) => {
                state.deleteItemStatus = HTTP_STATUS.LOADING
            })
            .addCase(deleteSalesItem.fulfilled, (state, action) => {
                state.deleteItemStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(deleteSalesItem.rejected, (state, action) => {
                state.deleteItemStatus = HTTP_STATUS.FAILED
            })
            .addCase(deleteSales.pending, (state, action) => {
                state.createStatus = HTTP_STATUS.LOADING
            })
            .addCase(deleteSales.fulfilled, (state, action) => {
                state.createStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(deleteSales.rejected, (state, action) => {
                state.createStatus = HTTP_STATUS.FAILED
            })
            .addCase(addAditionalItem.pending, (state, action) => {
                state.addAdditionalItemStatus = HTTP_STATUS.LOADING
            })
            .addCase(addAditionalItem.fulfilled, (state, action) => {
                state.addAdditionalItemStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(addAditionalItem.rejected, (state, action) => {
                state.addAdditionalItemStatus = HTTP_STATUS.FAILED
            })
            .addCase(fetchSalesReport.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchSalesReport.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.salesReport = action.payload
            })
            .addCase(fetchSalesReport.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(fetchLoadingReport.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchLoadingReport.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.loadingReport = action.payload
            })
            .addCase(fetchLoadingReport.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })            
            .addCase(fetchItemWiseSalesReport.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchItemWiseSalesReport.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.itemWiseSalesReport = action.payload
            })
            .addCase(fetchItemWiseSalesReport.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
    }
})

export const getSales = (state) => state.sales.sales;
export const getSalesReport = (state) => state.sales.salesReport;
export const getLoadingReport = (state) => state.sales.loadingReport;
export const getItemWiseSalesReport = (state) => state.sales.itemWiseSalesReport;
export const getSalesStock = (state) => state.sales.salesItems;
export const getSalesStaff = (state) => state.sales.salesStaff;
export const getSalesStatus = (state) => state.sales.status;
export const getCreateSalesStatus = (state) => state.sales.createStatus;
export const getDeleteSalesItemStatus = (state) => state.sales.deleteItemStatus;
export const getAdditionalSalesItemStatus = (state) => state.sales.addAdditionalItemStatus;

export const salesActions = Sales.actions

export default Sales.reducer