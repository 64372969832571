import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL, HTTP_STATUS } from '../../utils/constant'
import { newAxiosInstance } from '../../../axios'

const initialState = {
    userList: [],
    items: [],
    status: HTTP_STATUS.IDLE,
    itemStatus: HTTP_STATUS.IDLE,
    createStatus: HTTP_STATUS.IDLE
}

export const fetchUsers = createAsyncThunk('auth/users', async (postdata = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.get(BASE_URL + '/api/Auth/Users')
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const getRoleByUser = createAsyncThunk('auth/user/roles', async (userId, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.get(BASE_URL + `/api/Auth/get-role-by-user/${userId}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const getBaseItemByUser = createAsyncThunk('auth/user/base-items', async (userId, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.get(BASE_URL + `/api/Auth/get-base-item-by-user/${userId}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const getBaseItemByLoginUser = createAsyncThunk('auth/user/base-items-login-user', async (data = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.get(BASE_URL + `/api/Auth/get-base-item-by-user`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const createUser = createAsyncThunk('auth/create-user', async (postdata, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.post(BASE_URL + '/api/Auth/Register', postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const asignMainItemCategory = createAsyncThunk('auth/asign-main-item-category', async (postdata, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.post(BASE_URL + '/api/Auth/asign-main-item-category', postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const updateUser = createAsyncThunk('auth/update-user', async (postdata, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.put(BASE_URL + '/api/Auth/update-user', postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const resetPassword = createAsyncThunk('auth/reset-user-password', async (postdata, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.put(BASE_URL + '/api/Auth/reset-password', postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})
export const deleteUser = createAsyncThunk('auth/delete-user', async (userId, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.delete(BASE_URL + `/api/Auth/Delete-user/${userId}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const User = createSlice({
    name: 'user',
    initialState,
    reducers: {
        resetUsers: () => {
            return initialState;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchUsers.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.userList = action.payload
            })
            .addCase(fetchUsers.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(getBaseItemByLoginUser.pending, (state, action) => {
                state.itemStatus = HTTP_STATUS.LOADING
            })
            .addCase(getBaseItemByLoginUser.fulfilled, (state, action) => {
                state.itemStatus = HTTP_STATUS.SUCCEEDED
                state.items = action.payload
            })
            .addCase(getBaseItemByLoginUser.rejected, (state, action) => {
                state.itemStatus = HTTP_STATUS.FAILED
            })
            .addCase(createUser.pending, (state, action) => {
                state.createStatus = HTTP_STATUS.LOADING
            })
            .addCase(createUser.fulfilled, (state, action) => {
                state.createStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(createUser.rejected, (state, action) => {
                state.createStatus = HTTP_STATUS.FAILED
            })
            .addCase(updateUser.pending, (state, action) => {
                state.createStatus = HTTP_STATUS.LOADING
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.createStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.createStatus = HTTP_STATUS.FAILED
            })
            .addCase(resetPassword.pending, (state, action) => {
                state.createStatus = HTTP_STATUS.LOADING
            })
            .addCase(resetPassword.fulfilled, (state, action) => {
                state.createStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(resetPassword.rejected, (state, action) => {
                state.createStatus = HTTP_STATUS.FAILED
            })
            .addCase(deleteUser.pending, (state, action) => {
                state.createStatus = HTTP_STATUS.LOADING
            })
            .addCase(deleteUser.fulfilled, (state, action) => {
                state.createStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(deleteUser.rejected, (state, action) => {
                state.createStatus = HTTP_STATUS.FAILED
            })
            .addCase(asignMainItemCategory.pending, (state, action) => {
                state.createStatus = HTTP_STATUS.LOADING
            })
            .addCase(asignMainItemCategory.fulfilled, (state, action) => {
                state.createStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(asignMainItemCategory.rejected, (state, action) => {
                state.createStatus = HTTP_STATUS.FAILED
            })
    }
})

export const getUserList = (state) => state.user.userList;
export const getUserStatus = (state) => state.user.status;
export const getUserCreateStatus = (state) => state.user.createStatus;
export const getUserBaseItems = (state) => state.user.items;

export const { resetUsers } = User.actions

export default User.reducer