import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL, HTTP_STATUS } from '../../utils/constant'
import { newAxiosInstance } from '../../../axios'

const initialState = {    
    due_dates : [],
    status: HTTP_STATUS.IDLE,
    deleteStatus: HTTP_STATUS.IDLE
}

export const fetchDueDates = createAsyncThunk('creditorChequeDue/get-all', async (postdata = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.get(BASE_URL + '/api/CreditorCheque')
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const createDueDates = createAsyncThunk('creditorChequeDue/create', async (postdata, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.post(BASE_URL + '/api/CreditorCheque', postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const updateDueDates = createAsyncThunk('creditorChequeDue/update', async (postdata, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.put(BASE_URL + `/api/CreditorCheque/${postdata.id}`, postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const deleteDueDates = createAsyncThunk('creditorChequeDue/delete', async (id, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.delete(BASE_URL + `/api/CreditorCheque/${id}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const DueDates = createSlice({
    name: 'creditorChequeDue',
    initialState,
    reducers: {
        resetState: (state, action) => {
            return initialState;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchDueDates.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchDueDates.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.due_dates = action.payload
            })
            .addCase(fetchDueDates.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(deleteDueDates.pending, (state, action) => {
                state.deleteStatus = HTTP_STATUS.LOADING
            })
            .addCase(deleteDueDates.fulfilled, (state, action) => {
                state.deleteStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(deleteDueDates.rejected, (state, action) => {
                state.deleteStatus = HTTP_STATUS.FAILED
            })
    }
})

export const getDueDates = (state) => state.creditorChequeDue.due_dates;
export const getDueDatesStatus = (state) => state.creditorChequeDue.status;
export const getDueDatesDeleteStatus = (state) => state.creditorChequeDue.deleteStatus;

export const creditorChequeDueActions = DueDates.actions

export default DueDates.reducer