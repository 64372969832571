import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL, HTTP_STATUS } from '../../utils/constant'
import { newAxiosInstance } from '../../../axios'
import UrlBuilder from 'app/utils/UrlBuilder'
import { generateDownloadFileName, downloadFile } from 'app/utils/utils';

const initialState = {   
    items : [],
    stocks: [],
    stockReport: null,
    pendingBottleLoanReport: null,
    bottleLoanReport: null,
    bottleReport: null,
    loanInvoice: null,
    status: HTTP_STATUS.IDLE,
    createItemStatus: HTTP_STATUS.IDLE,
    deleteStatus: HTTP_STATUS.IDLE
}

export const fetchPendingBottleLoanReport = createAsyncThunk('bottle-item/get-pending-loan-report', async (data = null, { rejectWithValue }) => {
    try {
        let url = new UrlBuilder().filter(data, 'fileType').filter(data, 'stDate').filter(data, 'enDate').filter(data, 'repId').getUrl();
        let responseType = data?.fileType && parseInt(data.fileType) > 0 ? 'blob' : 'text';
        const response = await newAxiosInstance.get(BASE_URL + `/api/BrnStock/pending-bottle-loan-report${url}`,{ responseType })
        if(responseType === 'text') return response.data
        let filename = generateDownloadFileName(response.headers['content-disposition']);
        downloadFile(response.data, filename);       
        return null
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchBottleInvoice = createAsyncThunk('bottle-item/get-pending-loan-invoice', async (id = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.get(BASE_URL + `/api/BrnStock/bottle-loan-invoice/${id}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchBottleLoanReport = createAsyncThunk('bottle-item/get-loan-report', async (data = null, { rejectWithValue }) => {
    try {
        let url = new UrlBuilder().filter(data, 'fileType').filter(data, 'stDate').filter(data, 'enDate').filter(data, 'repId').filter(data, 'outletId').filter(data, 'loanId').getUrl();
        let responseType = data?.fileType && parseInt(data.fileType) > 0 ? 'blob' : 'text';
        const response = await newAxiosInstance.get(BASE_URL + `/api/BrnStock/bottle-loan-report${url}`,{ responseType })
        if(responseType === 'text') return response.data
        let filename = generateDownloadFileName(response.headers['content-disposition']);
        downloadFile(response.data, filename);       
        return null
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchBottleReport = createAsyncThunk('bottle-item/get-bottle-report', async (data = null, { rejectWithValue }) => {
    try {
        let url = new UrlBuilder().filter(data, 'fileType').filter(data, 'stDate').filter(data, 'enDate').filter(data, 'repId').filter(data, 'outletId').filter(data, 'return_type').getUrl();
        let responseType = data?.fileType && parseInt(data.fileType) > 0 ? 'blob' : 'text';
        const response = await newAxiosInstance.get(BASE_URL + `/api/BrnStock/bottle-report${url}`,{ responseType })
        if(responseType === 'text') return response.data
        let filename = generateDownloadFileName(response.headers['content-disposition']);
        downloadFile(response.data, filename);       
        return null
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchStocks = createAsyncThunk('bottle-item/get-stock', async (data, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.get(`${BASE_URL}/api/BrnStock`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchBrnStockItem = createAsyncThunk('bottle-item/get', async (data, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.get(`${BASE_URL}/api/BrnStockItem`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const createStockItem = createAsyncThunk('bottle-item/create', async (data, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.post(`${BASE_URL}/api/BrnStockItem`, data)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const updateStockItem = createAsyncThunk('bottle-item/update', async (data, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.put(`${BASE_URL}/api/BrnStockItem/${data.id}`, data)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const deleteStockItem = createAsyncThunk('bottle-item/delete', async (id, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.delete(`${BASE_URL}/api/BrnStockItem/${id}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const createStock = createAsyncThunk('bottle-item/create-stock', async (data, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.post(`${BASE_URL}/api/BrnStock`, data)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const updateStock = createAsyncThunk('bottle-item/update-stock', async (data, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.put(`${BASE_URL}/api/BrnStock/${data.stocks.id}`, data)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const BottleItem = createSlice({
    name: 'bottle_item',
    initialState,
    reducers: {
        reset: (state, action) => {
            return initialState
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchStocks.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchStocks.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.stocks = action.payload.map(x => ({
                    id: x.bottleStock[0]?.id,
                    item_code: x.item_code,
                    description: x.description,
                    capacity: x.capacity,
                    qty: x.bottleStock[0]?.qty,
                    primary_qty: x.bottleStock[0]?.primary_qty,
                    outlet_qty: x.bottleStock[0]?.outlet_qty,
                    damage_qty: x.bottleStock[0]?.damage_qty,
                    uprice: x.bottleStock[0]?.uprice,
                    bottle_item_id: x.bottleStock[0]?.bottle_item_id
                }))
            })
            .addCase(fetchStocks.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(fetchBrnStockItem.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchBrnStockItem.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.items = action.payload
            })
            .addCase(fetchBrnStockItem.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(createStockItem.pending, (state, action) => {
                state.createItemStatus = HTTP_STATUS.LOADING
            })
            .addCase(createStockItem.fulfilled, (state, action) => {
                state.createItemStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(createStockItem.rejected, (state, action) => {
                state.createItemStatus = HTTP_STATUS.FAILED
            })
            .addCase(updateStockItem.pending, (state, action) => {
                state.createItemStatus = HTTP_STATUS.LOADING
            })
            .addCase(updateStockItem.fulfilled, (state, action) => {
                state.createItemStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(updateStockItem.rejected, (state, action) => {
                state.createItemStatus = HTTP_STATUS.FAILED
            })
            .addCase(deleteStockItem.pending, (state, action) => {
                state.deleteStatus = HTTP_STATUS.LOADING
            })
            .addCase(deleteStockItem.fulfilled, (state, action) => {
                state.deleteStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(deleteStockItem.rejected, (state, action) => {
                state.deleteStatus = HTTP_STATUS.FAILED
            })
            .addCase(createStock.pending, (state, action) => {
                state.createItemStatus = HTTP_STATUS.LOADING
            })
            .addCase(createStock.fulfilled, (state, action) => {
                state.createItemStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(createStock.rejected, (state, action) => {
                state.createItemStatus = HTTP_STATUS.FAILED
            })
            .addCase(updateStock.pending, (state, action) => {
                state.createItemStatus = HTTP_STATUS.LOADING
            })
            .addCase(updateStock.fulfilled, (state, action) => {
                state.createItemStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(updateStock.rejected, (state, action) => {
                state.createItemStatus = HTTP_STATUS.FAILED
            })
            .addCase(fetchPendingBottleLoanReport.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchPendingBottleLoanReport.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.pendingBottleLoanReport = action.payload
            })
            .addCase(fetchPendingBottleLoanReport.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(fetchBottleLoanReport.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchBottleLoanReport.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.bottleLoanReport = action.payload
            })
            .addCase(fetchBottleLoanReport.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(fetchBottleReport.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchBottleReport.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.bottleReport = action.payload
            })
            .addCase(fetchBottleReport.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(fetchBottleInvoice.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchBottleInvoice.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.loanInvoice = action.payload
            })
            .addCase(fetchBottleInvoice.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
    }
})

export const getItems = (state) => state.bottleItem.items;
export const getStocks = (state) => state.bottleItem.stocks;
export const getStockReport = (state) => state.bottleItem.stockReport;
export const getpendingBottleLoanReport = (state) => state.bottleItem.pendingBottleLoanReport;
export const getBottleLoanReport = (state) => state.bottleItem.bottleLoanReport;
export const getBottleReport = (state) => state.bottleItem.bottleReport;
export const getLoanInvoice = (state) => state.bottleItem.loanInvoice;
export const getItemStatus = (state) => state.bottleItem.status;
export const getItemCreateStatus = (state) => state.bottleItem.createItemStatus;
export const getItemDeleteStatus = (state) => state.bottleItem.deleteStatus;
export const BottleItemActions = BottleItem.actions

export default BottleItem.reducer