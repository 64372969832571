import AuthGuard from 'app/auth/AuthGuard';
import chartsRoute from 'app/views/charts/ChartsRoute';
import inventoryRoutes from 'app/views/inventory/InventoryRoutes';
import userRoutes from 'app/views/user-accounts/UserRoutes';
import dashboardRoutes from 'app/views/dashboard/DashboardRoutes';
import materialRoutes from 'app/views/material-kit/MaterialRoutes';
import NotFound from 'app/views/sessions/NotFound';
import sessionRoutes from 'app/views/sessions/SessionRoutes';
import productRoutes from 'app/views/product/ProductRoutes';
import salesRoute from 'app/views/Sales/SalesRoute';
import salesMasterRoutes from 'app/views/SalesMasterRecord/SalesMasterRoutes';
import accountsRoute from 'app/views/accounts/AccountsRoute';
import accountMasterRoutes from 'app/views/AccountMasterRecord/AccountRoutes';
import employeeRoutes from 'app/views/EmployeeMasterRecord/EmployeeRoutes';
import InventoryRoutes from 'app/views/InventoryMasterRecord/InventoryRoutes';
import InventoryReportRoutes from 'app/views/inventoryReports/InventoryRoutes';
import AccountsReportRoutes from 'app/views/accountsReport/accountsRoutes';
import SalesReportRoutes from 'app/views/SalesReport/SalesRoutes';
import MatxLayout from './components/MatxLayout/MatxLayout';
import RootNavigation from './RootNavigation';

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...dashboardRoutes, 
      ...chartsRoute, 
      ...materialRoutes, 
      ...inventoryRoutes,
      ...userRoutes,
      ...salesMasterRoutes,
      ...salesRoute,
      ...accountsRoute,
      ...employeeRoutes,
      ...accountMasterRoutes,
      ...InventoryRoutes,
      ...InventoryReportRoutes,
      ...AccountsReportRoutes,
      ...SalesReportRoutes
    ],
  },
  ...productRoutes,
  ...sessionRoutes,
  { path: '/', element: <RootNavigation />},
  { path: '/not-found', element: <NotFound /> },
  { path: '*', element: <NotFound /> },
];

export default routes;
