// import useAuth from 'app/hooks/useAuth';
import { useSelector } from "react-redux";
import { getAuth, getRoles } from 'app/redux/reducers/AuthSlice';
import { flat } from 'app/utils/utils';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import AllPages from '../routes';
import { getProduct, getBottleAllowed } from 'app/redux/reducers/ProductSlice';

const userHasPermission = (pathname, roles, routes, bottleAllowed) => {
  if (!roles) {
    return false;
  }
  const matched = routes.find((r) => r.path === pathname);

  if(matched !== undefined && matched.routeRestrict !== undefined && matched.routeRestrict !== bottleAllowed) return false
  
  // const authenticated =
  //   matched && matched.auth && matched.auth.length ? matched.auth.includes(user.role) : true;
  // return authenticated;
  const authenticated =
    matched && matched.auth && matched.auth.length ? matched.auth?.some(arr => roles?.includes(arr)) : true;
  return authenticated;
};

const AuthGuard = ({ children }) => {

  let auth = useSelector(getAuth);
  let roles = useSelector(getRoles);
  const product = useSelector(getProduct);
  const bottleAllowed = useSelector(getBottleAllowed);
  // let {
  //   isAuthenticated,
  //   user
  // } = useAuth();
  const { pathname } = useLocation();
  const { id, route } = useParams();
  let newPathname;
  if(id || route){
    newPathname = id ? pathname.replace(id, ':id') : pathname
    newPathname = route ? newPathname.replace(route, ':route') : newPathname
  }else{
    newPathname = pathname
  }  
  const routes = flat(AllPages);

  const hasPermission = userHasPermission(newPathname, roles, routes, bottleAllowed);
  //   let authenticated = isAuthenticated && hasPermission;
    // let authenticated = auth && hasPermission;

  // // IF YOU NEED ROLE BASED AUTHENTICATION,
  // // UNCOMMENT ABOVE LINES
  // // AND COMMENT OUT BELOW authenticated VARIABLE

  // let authenticated = isAuthenticated;
  return (
    <>
      {auth ? 
        product ? 
          hasPermission ? (
            children
          ) : 
          (
            <Navigate replace to="/not-found" state={{ from: pathname }} />
          ) : 
          <Navigate replace to="/products/product-selection" state={{ from: pathname }} />
        : 
      (
        <Navigate replace to="/session/signin" state={{ from: pathname }} />
      )}
    </>
  );
};

export default AuthGuard;
