import React, { createContext, useState } from 'react'

const initial = {
    open: false,
    text: "",
    title: "",
    buttonColor: "",
    buttonText: "",
    cancleButtonColor: "",
    cancleButtonText: "",
    loading: false,
    confirmFunc: () => {},
    cancelFunc: () => {},
}

const DialogContext = createContext({
    confirm: initial,
    handleOpen: () => {},
    setLoading: () => {}
})

export const DialogProvider = ({ children }) => {

    const [confirm, setConfirm] = useState(initial)

    const handleOpen = (data = null) => {
        setConfirm({
            ...confirm,
            open: data?.open || initial.open,
            text: data?.text  || initial.text,
            title: data?.title || initial.title,
            buttonColor: data?.buttonColor || initial.buttonColor,
            cancleButtonColor: data?.cancleButtonColor || initial.cancleButtonColor,
            buttonText: data?.buttonText || initial.buttonText,
            cancleButtonText: data?.cancleButtonText || initial.cancleButtonText,
            loading: data?.loading || initial.loading,
            confirmFunc: data?.confirmFunc || initial.confirmFunc,
            cancelFunc: data?.cancelFunc || initial.cancelFunc
        })
    }

    const setLoading = (loading) => {
        setConfirm({
            ...confirm,
            loading: loading
        })
    }
    
    return (
        <DialogContext.Provider
            value={{
                confirm: confirm,
                handleOpen: handleOpen,
                setLoading: setLoading
            }}
        >
            {children}
        </DialogContext.Provider>
    )
}

export default DialogContext
