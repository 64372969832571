import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { getAlert, resetAlert } from 'app/redux/reducers/AlertSlice'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default React.memo(function PositionedSnackbar() {

  const alert = useSelector(getAlert);
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    open: false, 
    text: null, 
    severity: '',
    vertical: 'top',
    horizontal: 'center'
  });
  const { vertical, horizontal, open, text, severity } = state;


  const handleClose = () => {
    dispatch(resetAlert(alert?.severity || 'info'));
  };


  React.useEffect(() => {

    setState({ ...alert });        
    if(alert.open){
      setTimeout(() => {
        handleClose();
      }, 5000)        
    }
       
  }, [alert])

  return (
    <Box sx={{ width: '100%' }}>
      <Snackbar 
        open={open} 
        autoHideDuration={6000} 
        onClose={handleClose} 
        key={vertical + horizontal} 
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {text}
        </Alert>
      </Snackbar>
    </Box>
  );
})