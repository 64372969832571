import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const EmployeeBaseRole = Loadable(lazy(() => import('./EmployeeBaseRole/EmployeeBaseRole')));
const EmployeeRole = Loadable(lazy(() => import('./EmployeeRole/EmployeeRole')));
const Employee = Loadable(lazy(() => import('./Employee/Employee')));
const AlternativeRole = Loadable(lazy(() => import('./AlternativeRole/Alternative')));

const stockRoutes = [
    { path: '/employee/base-roles',  element: <EmployeeBaseRole />, auth: authRoles.sa },  
    { path: '/employee/base-roles/:id',  element: <EmployeeBaseRole />, auth: authRoles.sa },  
    { path: '/employee/roles',  element: <EmployeeRole />, auth: authRoles.sa },  
    { path: '/employee/roles/:id',  element: <EmployeeRole />, auth: authRoles.sa },  
    { path: '/employees',  element: <Employee />, auth: authRoles.sa },  
    { path: '/employees/:id',  element: <Employee />, auth: authRoles.sa },  
    { path: '/employee/alternative-role',  element: <AlternativeRole />, auth: authRoles.sa },  
    { path: '/employee/alternative-role/:id',  element: <AlternativeRole />, auth: authRoles.sa },  
];

export default stockRoutes;